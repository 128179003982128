import styles from "./PetAdoptionDetailPage.module.scss";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import animalAdoptionServiceInstance from "api/animalAdoptionService";
import commentsServiceInstance from "api/commentsService";

import Loading from "UI/components/Loading/Loading";
import { default as MenuApp } from "UI/components/Menu/Menu";
import InputComments from "UI/components/InputComments/InputComments";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";
import ShareDialog from "UI/components/shareDialog/ShareDialog";

import ImageGallery from "react-image-gallery";

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';
import PostTypeEnum from 'common/enums/PostTypeEnum';


const PetAdoptionDetailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", iconType: "info", function: async () => { } });

    const [loading, setLoading] = useState(false);
    const [animal, setAnimal] = useState(null);

    const [commentList, setCommentList] = useState(null);

    const [comment, setComment] = useState("");

    const params = useParams();

    const refShareDialog = useRef(null);

    useEffect(async () => {window.scrollTo(0, 0);

        setLoading(true);

        var result = await animalAdoptionServiceInstance.get(params.id);
        if (result.code == 200) {
            setAnimal(result.data);
        }

        var resultComments = await commentsServiceInstance.getAll(PostTypeEnum.AdoptionPost, params.id);
        if (resultComments.code == 200) {
            setCommentList(resultComments.data);
        }

        setLoading(false);

    }, []);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }


            <div className={styles.mainContainer}>

                <MenuApp close={true} onNoRegisterAction={() => {
                    setDialog({
                        ...dialog, description: "Debe iniciar sesión para esta acción", show: true, iconType: "info", function: async () => {
                            navigate('/LoginPage', { replace: false, state: { showLogin: true } });
                        }
                    })
                }
                } />

                <div className={styles.mainContainerInside}>

                    {
                        animal ? (
                            <>
                                <div className={styles.petsContainer}>
                                    {


                                        <div className={styles.petsCellContainer} key={animal.id}>

                                            <div className={styles.postTitleContainer}>
                                                <h5 className={styles.postTitle}>{myConst.UtilsInstance.camelize(animal.title)}</h5>
                                                {myConst.UtilsInstance.getUser() && animal.idUser == myConst.UtilsInstance.getUser().id ? (<i className="fa-solid fa-pencil" onClick={() => {
                                                    navigate('/PetAdoptionCreationPage', { state: { item: animal }, replace: false });
                                                }}></i>) : (<></>)}
                                            </div>


                                            {animal.images.length !== 0 ? (
                                                <div className={styles.gallery}>
                                                    <ImageGallery showPlayButton={false} showThumbnails={false} items={myConst.UtilsInstance.gallery(animal.images)} />
                                                </div>
                                            ) : (<></>)}

                                            <p className={styles.postDescription}>{animal.description}</p>

                                            <div className={styles.petsCellTagsContainer}>
                                                <i className="fa-solid fa-venus-mars"></i>
                                                <p>{animal.sex === "M" ? "Macho" : "Hembra"}</p>
                                            </div>

                                            <div className={styles.petsCellTagsContainer}>
                                                <i className="fa-solid fa-phone"></i>
                                                <p>{animal.phone}</p>
                                            </div>

                                            <div className={styles.petsCellTagsContainer}>
                                                <i className="fa-solid fa-earth-americas"></i>
                                                <p>{animal.nombre_provincia + " " + animal.nombre_canton + " " + animal.nombre_distrito}</p>
                                            </div>
                                            
                                            <div className={styles.shareIcon} onClick={() => { refShareDialog.current.showDialog(myConst.appUrl + "PetAdoptionDetailPage/" + animal.id); }}>
                                                <i className="fa-solid fa-share-nodes"></i>
                                            </div>
                                        </div>


                                    }

                                </div>

                                <div className={styles.commentsContainer}>
                                    <h1>Comentarios</h1>

                                    <InputComments title="Correo electrónico:" type="email" icon="fa-solid fa-envelope" value={comment} onChange={(inputValue) => {
                                        setComment(inputValue.target.value)
                                    }} onSend={async () => {

                                        if (myConst.UtilsInstance.getUser()) {

                                            if (comment) {
                                                var result = await commentsServiceInstance.create(PostTypeEnum.AdoptionPost, myConst.UtilsInstance.getUser().id, animal.id, comment.trim());
                                                if (result.code != 200) {
                                                    ShowDialog(dialog, setDialog, "Guardado", "Publicación guardada", async () => { }, result);
                                                } else {
                                                    setLoading(true);
                                                    var resultComments = await commentsServiceInstance.getAll(PostTypeEnum.AdoptionPost, params.id);
                                                    if (resultComments.code == 200) {
                                                        setCommentList(resultComments.data);
                                                    }
                                                    setComment("");
                                                    setLoading(false);
                                                }
                                            } else {
                                                setDialog({ ...dialog, description: "El comentario no puede estar en blanco", show: true, iconType: "error" })
                                            }

                                        } else {
                                            setDialog({
                                                ...dialog, description: "Debe iniciar sesión para comentar", show: true, iconType: "error", function: async () => {
                                                    navigate('/LoginPage', { replace: false, state: { showLogin: true } });
                                                }
                                            })
                                        }




                                    }} />

                                    {
                                        commentList && commentList.length > 0 ?

                                            (<div className={styles.commentListContainer}>

                                                {commentList.map((item) => (
                                                    <div className={styles.commentItemContainer} key={item.id}>
                                                        <div className={styles.comment} >
                                                            <h3>{item.name}</h3>
                                                            <pre>{item.text}</pre>
                                                        </div>

                                                        {myConst.UtilsInstance.getUser() && item.idUser == myConst.UtilsInstance.getUser().id ? (
                                                            <i className="fa-solid fa-trash" onClick={async () => {
                                                                setLoading(true);
                                                                var resultDelete = await commentsServiceInstance.delete(item.id);
                                                                if (resultDelete.code == 200) {
                                                                    var resultComments = await commentsServiceInstance.getAll(PostTypeEnum.AdoptionPost, params.id);
                                                                    if (resultComments.code == 200) {
                                                                        setCommentList(resultComments.data);
                                                                    }
                                                                }
                                                                setLoading(false);
                                                            }}></i>
                                                        ) : (<></>)}


                                                    </div>
                                                ))}

                                            </div>)

                                            : (
                                                <div className={styles.emptyContainer}>
                                                    <i className="fa-solid fa-comments"></i>
                                                    <h2>Sin comentarios</h2>
                                                </div>
                                            )
                                    }

                                </div>

                            </>

                        ) : (
                            <div className={styles.emptyContainer}>
                                <i className="fa-regular fa-rectangle-list"></i>
                                <h2>Este animal no se encuentra disponible en este momento</h2>
                            </div>
                        )
                    }

                </div>

                <ShareDialog ref={refShareDialog} />

            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, iconType: "info", function: async () => { } })
                }} />) : (<></>)
            }
            <img className="appIconBackground" src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />

        </div>
    );


}

export default PetAdoptionDetailPage;