import * as myConst from '../common/common.js';

class AddressService {

    provincia = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: myConst.headersBase,
            };
            const response = await fetch((myConst.baseUrl + 'address/provincia'), requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    provinciaNA = async () => {
        var result = await this.provincia();
        if(result.code === 200){
            result.data = [{code: 'NA', name: 'Todas'}].concat(result.data);
        }
        return result;
    };

    canton = async (code) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: myConst.headersBase,
            };
            const response = await fetch((myConst.baseUrl + 'address/canton?' + new URLSearchParams({
                code: code,
            })), requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    cantonNA = async (code) => {
        var result = await this.canton(code);
        if(result.code === 200){
            result.data = [{code: 'NA', name: 'Todas'}].concat(result.data);
        }
        return result;
    };

    distrito = async (code) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: myConst.headersBase,
            };
            const response = await fetch((myConst.baseUrl + 'address/distrito?' + new URLSearchParams({
                code: code,
            })), requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    distritoNA = async (code) => {
        var result = await this.distrito(code);
        if(result.code === 200){
            result.data = [{code: 'NA', name: 'Todas'}].concat(result.data);
        }
        return result;
    };

}

const addressServiceInstance = new AddressService();
export default addressServiceInstance;