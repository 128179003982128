import React,{useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider,useLocation  } from "react-router-dom";

//main app pages
import LoginPage from "./UI/Views/user/login/loginPage";
import CreateUserPage from "./UI/Views/user/createUser/CreateUserPage";
import UpdateUserPage from "./UI/Views/user/UpdateUserPage/UpdateUserPage";
import PasswordRecoveryPage from "./UI/Views/user/passwordRecoveryPage/PasswordRecoveryPage";

//pet insurance
import DashboardPage from "./UI/Views/petInsurance/dashboard/DashboardPage";
import PetCreationPage from "./UI/Views/petInsurance/petCreation/PetCreationPage";
import CodeDetailPage from "./UI/Views/petInsurance/codeDetail/CodeDetailPage";
import FindPage from "./UI/Views/petInsurance/findPage/FindPage";

//general
import AboutUsPage from "./UI/Views/generalView/aboutUs/AboutUsPage";
import ContactUsPage from "./UI/Views/generalView/contactUsPage/ContactUsPage";
import ErrorPage from "./UI/Views/generalView/errorPage/ErrorPage";


//pet adoption
import PetAdoptionDashboardPage from "./UI/Views/petAdoption/petAdoptionDashboard/PetAdoptionDashboardPage";
import PetAdoptionCreationPage from "./UI/Views/petAdoption/petAdoptionCreationPage/PetAdoptionCreationPage";
import PetAdoptionDetailPage from "./UI/Views/petAdoption/petAdoptionDetailPage/PetAdoptionDetailPage";

//shelter
import ShelterCreationPage from "./UI/Views/shelterHouse/shelterCreation/ShelterCreationPage";
import ShelterDashboardPage, {ShelterDashboardDetailPage} from "./UI/Views/shelterHouse/shelterDashboard/ShelterDashboardPage";

//shelter

import "./index.scss";


const baseRouteList = [
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    path: "/LoginPage",
    element: <LoginPage />,
  },
  {
    path: "/CreateUserPage",
    element: <CreateUserPage />,
  },
  {
    path: "/UpdateUserPage",
    element: <UpdateUserPage />,
  },
  {
    path: "/PetCreationPage",
    element: <PetCreationPage />,
  },
  {
    path: "/CodeDetailPage",
    element: <CodeDetailPage />,
  },
  {
    path: "/AboutUsPage",
    element: <AboutUsPage />,
  },
  {
    path: "/PasswordRecoveryPage",
    element: <PasswordRecoveryPage />,
  },
  {
    path: "/ContactUsPage",
    element: <ContactUsPage />,
  },
  {
    path: "/F/:id",
    element: <FindPage />,
  },
  {
    path: "/",
    element: <DashboardPage />,
  },


  //pet adoption
  {
    path: "/PetAdoptionDashboardPage",
    element: <PetAdoptionDashboardPage />,
  },
  {
    path: "/PetAdoptionCreationPage",
    element: <PetAdoptionCreationPage />,
  },
  {
    path: "/PetAdoptionDetailPage/:id",
    element: <PetAdoptionDetailPage />,
  },


  //shelter house
  {
    path: "/ShelterCreationPage",
    element: <ShelterCreationPage />,
  },
  {
    path: "/ShelterPage",
    element: <ShelterDashboardPage />,
  },
  {
    path: "/ShelterDetailPage/:id",
    element: <ShelterDashboardDetailPage />,
  }
]


const router = createBrowserRouter(baseRouteList);

function App() {
  return  <RouterProvider router={router} />
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
