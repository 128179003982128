import * as myConst from '../common/common.js';

class AnimalService {

    create = async (name, idUser, status, special_needs, castrated, sex, race,image) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ name: name, idUser: idUser, status: status, special_needs: special_needs, castrated: castrated, sex: sex, race: race,image:image })
            };

            const response = await fetch(myConst.baseUrl + 'animal/addAnimal', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    update = async (name, idUser, status, special_needs, castrated, sex, race,image, id) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ name: name, idUser: idUser, status: status, special_needs: special_needs, castrated: castrated, sex: sex, race: race,image:image, id: id })
            };

            const response = await fetch(myConst.baseUrl + 'animal/updateAnimal', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    getAll = async (idUser) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: myConst.headersBase,
            };
            const response = await fetch((myConst.baseUrl + 'animal/getAllAnimal?' + new URLSearchParams({
                idUser: idUser,
            })), requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    get = async (id) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: myConst.headersBase,
            };
            const response = await fetch((myConst.baseUrl + 'animal/getAnimal?'+ new URLSearchParams({
                id: id,
            })), requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    delete = async (id) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ id: id })
            };

            const response = await fetch(myConst.baseUrl + 'animal/deleteAnimal', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

}

const animalServiceInstance = new AnimalService();
export default animalServiceInstance;