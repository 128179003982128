import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import styles from "./AlertDialog.module.scss";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function AlertDialog(props) {

    const matches = useMediaQuery('(min-width:600px)');

    return (

        <Dialog
            PaperProps={{ sx: { borderRadius: "25px", width: !matches ? '250px' : "300px" } }}
            BackdropProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none"
                }
            }}
            open={true}
        >

            <div className={styles.alertContainer}>
                {props.iconType && props.iconType === "check" ? (<i className={`fa-solid fa-circle-check fa-2xl ${styles.iconBlue}`}></i>) : (<></>)}
                {props.iconType && props.iconType === "error" ? (<i className={`fa-solid fa-triangle-exclamation fa-2xl  ${styles.iconRed}`}></i>) : (<></>)}
                {!props.iconType || props.iconType === "info" ? (<i className={`fa-solid fa-circle-info fa-2xl  ${styles.iconBlue}`}></i>) : (<></>)}

                <h2 className={styles.title}>{props.title}</h2>
                <h3 className={styles.description}>{props.description}</h3>

                <div className={styles.loginBottonButton}>
                    <button
                        className={styles.primaryButton}
                        onClick={props.onClick}>Aceptar</button>

                </div>

            </div>



        </Dialog>

    );
}

export function ShowDialog(dialog, setDialog, title, description, action, response) {

    if (response != null && response.code != 200) {
        setDialog({
            ...dialog, title: "Atención", description: response.data, show: true, iconType: "error", function: async () => { }
        })
    } else {
        setDialog({
            ...dialog, title: title, description: description, show: true, iconType: "check", function: action
        })
    }

}