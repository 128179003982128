import styles from "./WelcomePage.module.scss";
import Carousel from 'react-material-ui-carousel'

const WelcomePage = () => {
    return (
        <div className={styles.carouselContainer}>
            <Carousel animation="fade" cycleNavigation="true">
                <div>
                    <div className={styles.mainContainerInsideNoBackground}>
                        <img src={require('UI/assets/images/dogScan.png').default} />
                        <h2 className={styles.titleWhite}>Asegura a tu mascota</h2>
                        <p>Registra a tu mascota y asegúrala mediante nuestro sistema, en caso de que se pierda, únicamente necesitarán escanear su código y darán con tus datos de contacto.</p>
                    </div>
                </div>

                <div>
                    <div className={styles.mainContainerInsideNoBackground}>
                        <img src={require('UI/assets/images/adoptUs.png').default} />
                        <h2 className={styles.titleWhite}>Animales en adopción</h2>
                        <p>Encuentra y publica animales que se encuentren en adopción para encontrarles su hogar ideal de la mejor manera.</p>
                    </div>
                </div>
            </Carousel>
        </div>
    );
}

export default WelcomePage;