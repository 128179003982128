import styles from "./PasswordRecoveryPage.module.scss";

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import userServiceInstance from "api/userService";

import Loading from "UI/components/Loading/Loading";
import InputIcon from "UI/components/InputIcon/InputIcon";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";
import PowerBy from "UI/components/powerBy/PowerBy";

import * as myConst from 'common/common';



const PasswordRecoveryPage = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: "Atención", description: "", iconType: "info", function: async () => { }  });

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(async () => {window.scrollTo(0, 0);

    }, []);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

               <div className={styles.mainContainerInside}>


               <i className={`${styles.iconChain} fa-solid fa-unlock`}></i>

                    <h2 className={styles.loginTitle}>Recuperar contraseña</h2>

                    <InputIcon title="Correo electrónico:" type="email" icon="fa-solid fa-envelope" value={email} onChange={(inputValue) => {
                        setEmail(inputValue.target.value)
                    }} />

                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {
                                if (
                                    email !== null && email !== "") {
                                    setLoading(true);
                                    var result = await userServiceInstance.passwordRecovery(email);
                                    setLoading(false);
                                    ShowDialog(dialog, setDialog,"Correo electrónico enviado", "Recibirás un correo electrónico con tu contraseña" , async () => {
                                        navigate('/LoginPage',   { state: { showLogin:true}, replace: false });
                                    }, result);
                                } else {
                                    setDialog({ ...dialog, description: "El correo electrónico es requerido", show: true, iconType: "error" })
                                }
                            }}>Recuperar contraseña</button>
                        <h3 className={styles.createAccountText} onClick={() => {
                             navigate('/LoginPage',   { state: { showLogin:true}, replace: false });
                        }}>Volver</h3>
                    </div>
                </div>
                <PowerBy />
            </div>

            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: "Atención", iconType: "info" , function: async () => { }})
                }} />) : (<></>)
            }

        </div>
    );


}

export default PasswordRecoveryPage;

