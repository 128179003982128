import styles from "./CodeDetailPage.module.scss";

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import Loading from "UI/components/Loading/Loading";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";
import Menu from "UI/components/Menu/Menu";

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';

import QRCode from "react-qr-code";
import { toPng } from 'html-to-image';
import ImageGallery from "react-image-gallery";

const CodeDetailPage = () => {
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", function: async () => { } });
    const navigate = useNavigate();
    const location = useLocation();

    var user = myConst.UtilsInstance.getUser();

    useEffect(() => {window.scrollTo(0, 0);
        if (myConst.UtilsInstance.getUser() === null) { navigate('/LoginPage', { replace: true }); }
    }, []);

    const elementRef = useRef(null);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

                <Menu close={true} />

                <div className={styles.mainContainerInside}>

                    <h2 className={styles.loginTitle}>Código de {location.state.data.name}</h2>

                    <div ref={elementRef} id="QRCode" style={{ backgroundColor: 'white', padding: '10px', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                        <QRCode
                            size={1000}
                            style={{ height: "auto", width: "100%", backgroundColor: 'white' }}
                            value={`${myConst.findUrl}${location.state.data.id}`}
                            viewBox={`0 0 1000 1000`}
                        />
                    </div>

                    <p className={styles.qrHint}>*Este código nunca cambiará*<br />Escanea con la cámara de tu celular</p>

                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {
                                setLoading(true);
                                toPng(elementRef.current, { cacheBust: false })
                                    .then((dataUrl) => {
                                        const link = document.createElement("a");
                                        link.download = "Código de " + location.state.data.name + ".png";
                                        link.href = dataUrl;
                                        link.click();
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                                setLoading(false);
                            }}>Descargar código</button>

                        <h3 className={styles.createAccountText} onClick={() => {
                            navigate(-1);
                        }}>Volver</h3>

                    </div>
                </div>


                <div className={styles.mainContainerInsideNoBackground}>
                    <h2 className={styles.titleWhite}>¿Que hacer con el código?</h2>

                    <h4 className={styles.titleWhiteH3}>Impresión en calcomanía</h4>
                    <p>Puedes imprimir el código con papel calcomanía y adherirlo a una placa antigua o a una realizada con materiales reciclados, como una botella o envase plástico.</p>

                    <p className={styles.exampleText}>Ejemplo de fabricación casera:</p>

                    <div className={styles.gallery}>

                        <ImageGallery showPlayButton={false} items={[
                            {
                                originalHeight: 300,
                                thumbnailHeight: 50,
                                original: require('UI/assets/images/create1.jpg').default,
                                thumbnail: require('UI/assets/images/create1.jpg').default,
                            },
                            {
                                originalHeight: 300,
                                thumbnailHeight: 50,
                                original: require('UI/assets/images/create2.jpg').default,
                                thumbnail: require('UI/assets/images/create2.jpg').default,
                            },
                            {
                                originalHeight: 300,
                                thumbnailHeight: 50,
                                original: require('UI/assets/images/create3.jpg').default,
                                thumbnail: require('UI/assets/images/create3.jpg').default,
                            },
                            {
                                originalHeight: 300,
                                thumbnailHeight: 50,
                                original: require('UI/assets/images/create4.jpg').default,
                                thumbnail: require('UI/assets/images/create4.jpg').default,
                            },
                            {
                                originalHeight: 300,
                                thumbnailHeight: 50,
                                original: require('UI/assets/images/create5.jpg').default,
                                thumbnail: require('UI/assets/images/create5.jpg').default,
                            }
                        ]} />


                    </div>

                    <p className={styles.exampleText}>Ejemplo de fabricación material sólido:</p>
                    <iframe className={styles.youtube} height="315" src="https://www.youtube.com/embed/hF2RR5afrXc?si=T6GcjnA0IHrfuNea" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe>

                    <h4 className={styles.titleWhiteH3}>Grabado en madera</h4>
                    <p>Puedes llevar el código a un local de grabado láser para crear una placa de madera.</p>


                    <img className={styles.plateImage} src={require('UI/assets/images/placa_madera.svg').default} />

                    <h4 className={styles.titleWhiteH3}>Placa metálica</h4>
                    <p>Puedes llevar el código a un centro especializado donde fabriquen una placa metálica con el código.</p>

                    <img className={styles.plateImage} src={require('UI/assets/images/placa_metal.svg').default} />

                </div>
            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, function: async () => { } })
                }} />) : (<></>)
            }
        </div>
    );


}

export default CodeDetailPage;