import * as myConst from '../common/common.js';

class CdnService {

    create = async (image) => {
        try {
            const sendData = new FormData();
            sendData.append("fileSave", image);

            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBaseFormData,
                body: sendData
            };
            const response = await fetch(myConst.baseUrl + 'cdn/uploadFile', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    delete = async (image) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ image: image })
            };
            const response = await fetch(myConst.baseUrl + 'cdn/deleteFile', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };
}

const cdnServiceInstance = new CdnService();
export default cdnServiceInstance;