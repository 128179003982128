import styles from "./Menu.module.scss";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import * as myConst from '../../../common/common';

const Menu = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const navigate = useNavigate();

    return (

        <div className={styles.out}>

            {menuOpen ? (
                <div className={styles.menuContainerBackground} onClick={
                    () => {
                        setMenuOpen(false);
                    }
                }>  </div>) : (<></>)}

            <div className={styles.headerFullBar}>

                <div className={styles.headerColum}>

                    <div className={styles.header}>

                        <div className={styles.userIconContainer} onClick={
                            () => {
                                setMenuOpen(!menuOpen);
                            }
                        }>
                            <i className={menuOpen ? `fa-solid fa-bars-staggered ${styles.menuIcon}` : `fa-solid fa-bars ${styles.menuIcon}`}></i>
                        </div>

                        <div className={styles.iconsGroup}>

                            <div className={styles.userIconContainerShelter} onClick={
                                () => {
                                    if (!myConst.UtilsInstance.getUser() && props.onNoRegisterAction) {
                                        props.onNoRegisterAction();
                                    } else {
                                        navigate('/ShelterPage', { replace: false });
                                    }
                                }
                            }>

                                <i className={`fa-solid fa-house-medical`}></i>
                                <p>Casa cuna</p>
                            </div>

                            <div className={styles.userIconContainer} onClick={
                                () => {
                                    if (props.close) {
                                        navigate(-1);
                                    } else {
                                        if (!myConst.UtilsInstance.getUser() && props.onNoRegisterAction) {
                                            props.onNoRegisterAction();
                                        } else {
                                            navigate('/UpdateUserPage', { replace: false });
                                        }
                                    }
                                }
                            }>
                                {!props.close ? (<i className={`fa-solid fa-user ${styles.userIcon}`}></i>) : (<i className={`fa-solid fa-xmark ${styles.userIcon}`}></i>)}

                            </div>

                        </div>

                    </div>

                    {menuOpen ? (

                        <div className={styles.menuContainer} >

                            <div className={styles.out}>
                                <ul className={`${styles.userIconContainerAnimated}`} >
                                    <a onClick={() => {
                                        if (!myConst.UtilsInstance.getUser() && props.onNoRegisterAction) {
                                            props.onNoRegisterAction();
                                        } else {
                                            navigate('/', { replace: false });
                                            setMenuOpen(!menuOpen);
                                        }
                                    }}> <i className="fa-solid fa-house fa-2xs"></i> <li>Mis mascotas</li></a>

                                    <a onClick={() => {
                                        navigate('/ShelterPage', { replace: false });
                                        setMenuOpen(!menuOpen);
                                    }}> <i className="fa-solid fa-house-medical fa-2xs"></i> <li>Casa cuna</li></a>

                                    <a onClick={() => {
                                        navigate('/PetAdoptionDashboardPage', { replace: false });
                                        setMenuOpen(!menuOpen);
                                    }}> <i className="fa-solid fa-house-medical-flag fa-2xs"></i> <li>Animales en adopción</li></a>

                                    <a onClick={() => {
                                        navigate('/AboutUsPage', { replace: false });
                                        setMenuOpen(!menuOpen);
                                    }}> <i className="fa-solid fa-circle-info fa-2xs"></i> <li>Acerca de nosotros</li></a>

                                    <a onClick={() => {
                                        navigate('/ContactUsPage', { replace: false });
                                        setMenuOpen(!menuOpen);
                                    }}> <i className="fa-solid fa-comments fa-2xs"></i> <li>Contáctenos</li></a>

                                    {myConst.UtilsInstance.getUser() !== null ? (<a onClick={() => {
                                        myConst.UtilsInstance.setUser(null);
                                        myConst.UtilsInstance.cleanUserCookie();
                                        navigate('/LoginPage', { replace: true });
                                        setMenuOpen(!menuOpen);
                                    }}> <i className="fa-solid fa-door-open fa-2xs"></i> <li>Cerrar sesión</li></a>) : (<></>)}

                                </ul>
                            </div>
                        </div>

                    ) : (<></>)}

                </div>
            </div>

        </div>
    );

}

export default Menu;