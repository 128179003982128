import * as myConst from '../common/common.js';

class UserService {

    create = async (name, email, password, phone, address) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ name: name, email: email, password: password, phone: phone, address: address })
            };

            const response = await fetch(myConst.baseUrl + 'user/addUser', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    update = async (id, name, phone, address) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ id: id, name: name, phone: phone, address: address })
            };

            const response = await fetch(myConst.baseUrl + 'user/updateUser', requestOptions);
            if (!response.ok) {}
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    login = async (email, password) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ email: email, password: password })
            };

            const response = await fetch(myConst.baseUrl + 'user/getUser', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    getUserId = async (id) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ id: id})
            };

            const response = await fetch(myConst.baseUrl + 'user/getUserId', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    passwordRecovery = async (email) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ email: email })
            };

            const response = await fetch(myConst.baseUrl + 'user/passwordRecovery', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    sendEmail = async (text) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ text: text })
            };

            const response = await fetch(myConst.baseUrl + 'user/sendEmail', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };
}

const userServiceInstance = new UserService();
export default userServiceInstance;