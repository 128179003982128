import styles from "./InputComments.module.scss";
import React, { useRef, useState } from "react";

const InputIcon = (props) => {


    var max = 255;
    if (props.max != null) {
        max = props.max;
    }

    const MIN_TEXTAREA_HEIGHT = 35;

    const textareaRef = React.useRef(null);

    React.useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        textareaRef.current.style.height = "inherit";
        // Set height
        textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight,
            MIN_TEXTAREA_HEIGHT
        )}px`;
    }, [props.value]);

    return (
        <div className={styles.inputOutside}>
            <div className={styles.inputText}>
                <textarea
                    rows={1}
                    placeholder="Añade un comentario..."
                    type={"text"}
                    value={props.value}

                    ref={textareaRef}
                    style={{
                        minHeight: MIN_TEXTAREA_HEIGHT,
                        resize: "none"
                    }}
                    
                    onChange={props.onChange}
                    maxLength={`${max}`}
                >
                </textarea>
            </div>
            <div className={styles.commentButton} onClick={props.onSend}>
                <i className="fa-solid fa-comment"></i>
            </div>

        </div>
    );

}

export default InputIcon;