import styles from "./ContactUsPage.module.scss";

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import userServiceInstance from "api/userService";

import Loading from "UI/components/Loading/Loading";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";
import Menu from "UI/components/Menu/Menu";
import InputIcon from "UI/components/InputIcon/InputIcon";

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';

const ContactUsPage = () => {
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", iconType: "info", function: async () => { } });
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");

    var user = myConst.UtilsInstance.getUser();

    useEffect(() => {window.scrollTo(0, 0);

    }, []);

    const elementRef = useRef(null);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

                <Menu close={true} />

                <h2 className={`${styles.firstTitle} ${styles.loginTitle}`}>{"Contáctenos"}</h2>

                <div className={styles.mainContainerInside}>

                    <InputIcon title="Tú correo electrónico:" type="text" icon="fa-solid fa-at"
                        value={email} onChange={(e) => { setEmail(e.target.value) }} />

                    <InputIcon title="Asunto:" type="text" icon="fa-solid fa-a"
                        value={subject} onChange={(e) => { setSubject(e.target.value) }} />

                    <InputIcon title="Mensaje:" type="text" icon="fa-solid fa-envelope-open-text" multiLine={true} max={500} rows="6"
                        value={text} onChange={(e) => { setText(e.target.value) }} />


                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {


                                if (
                                    email !== null && email !== "" &&
                                    subject !== null && subject !== "" &&
                                    text !== null && text !== "") {
                                    setLoading(true);
                                    var result = await userServiceInstance.sendEmail(
                                        `Email: ${email} \nAsunto:  ${subject} \nTexto:  ${text}`
                                    );
                                    setLoading(false);
                                    ShowDialog(dialog, setDialog, "Correo electrónico enviado", "Muchas gracias por ponerte en contacto", async () => {
                                        navigate(-1);
                                    }, result);
                                } else {
                                    setDialog({ ...dialog, description: "Todos los espacios son requeridos", show: true, iconType: "error" })
                                }

                            }}>Enviar</button>
                    </div>
                </div>

                <h2 className={styles.loginTitle}>{"Otros medios"}</h2>

                <div className={styles.mainContainerInside}>
                    <div className={styles.otherContactInfo}>
                        <div className={styles.otherContactInfoItem}>
                            <i className="fa-brands fa-facebook"></i>
                            <a href="https://www.facebook.com/profile.php?id=61555735427171" style={{ textDecoration: "none" }}>
                                <p style={{ cursor: "pointer" }}>Facebook Desarrollo Moncake</p>
                            </a>
                        </div>
                        <div className={styles.otherContactInfoItem}>
                            <i className="fa-solid fa-envelope"></i>
                            <p>desarrollomoncake@gmail.com</p>
                        </div>
                        <div className={styles.otherContactInfoItem}>
                            <i className="fa-solid fa-phone"></i>
                            <p>+506 86672823</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, iconType: "info", function: async () => { } })
                }} />) : (<></>)
            }
        </div>
    );


}

export default ContactUsPage;