import * as myConst from '../common/common.js';

class AnimalAdoptionService {

    getAll = async (sex,provincia,canton,distrito,user,page) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: myConst.headersBase,
            };
            const response = await fetch((myConst.baseUrl + 'animalAdoption/getAll?' + new URLSearchParams({
                sex: sex,
                provincia: provincia,
                canton: canton,
                distrito: distrito,
                user: user,
                page:page,
            })), requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    get = async (idAnimalAdoption) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: myConst.headersBase,
            };
            const response = await fetch((myConst.baseUrl + 'animalAdoption/get?'+ new URLSearchParams({
                idAnimalAdoption: idAnimalAdoption,
            })), requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    create = async (phone, description, title, sex, provincia, canton, distrito,idUser,images) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ phone: phone, description: description, title: title, sex: sex, provincia: provincia, canton: canton, distrito: distrito,idUser:idUser,images:images })
            };

            const response = await fetch(myConst.baseUrl + 'animalAdoption/add', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    update = async (phone, description, title, sex, provincia, canton, distrito,id,idUser,images,oldImages) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ phone: phone, description: description, title: title, sex: sex, provincia: provincia, canton: canton, distrito: distrito,idUser:idUser,images:images,oldImages:oldImages,id:id })
            };

            const response = await fetch(myConst.baseUrl + 'animalAdoption/update', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    delete = async (id,oldImages) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ oldImages:oldImages,id:id })
            };

            const response = await fetch(myConst.baseUrl + 'animalAdoption/delete', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

}

const animalAdoptionServiceInstance = new AnimalAdoptionService();
export default animalAdoptionServiceInstance;