import styles from "./UpdateUserPage.module.scss";

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import userServiceInstance from "api/userService";

import Loading from "UI/components/Loading/Loading";
import InputIcon from "UI/components/InputIcon/InputIcon";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";
import Menu from "UI/components/Menu/Menu";

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';

const UpdateUserPage = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", function: async () => { } });
    const navigate = useNavigate();

    var user = myConst.UtilsInstance.getUser();

    useEffect(() => {window.scrollTo(0, 0);
        if (myConst.UtilsInstance.getUser() === null) { navigate('/LoginPage', { replace: true }); } else {
            setPhone(user.phone);
            setName(user.name);
            setAddress(user.address);
        }
    }, []);



    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

                <Menu close={true}/>

                <div className={styles.mainContainerInside}>

                    <h2 className={styles.loginTitle}>Mi Perfil</h2>

                    <InputIcon title="Nombre:" type="text" icon="fa-solid fa-signature" value={name} onChange={(inputValue) => {
                        setName(inputValue.target.value)
                    }} />

                    <InputIcon title="Teléfono:" type="text" icon="fa-solid fa-phone-volume" value={phone} onChange={(inputValue) => {
                        setPhone(inputValue.target.value)
                    }} />

                    <InputIcon title="Dirección:" type="text" icon="fa-solid fa-earth-americas" multiLine={true} max={500} value={address} onChange={(inputValue) => {
                        setAddress(inputValue.target.value)
                    }} />

                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {

                                if (
                                    name !== null && name !== "" &&
                                    phone !== null && phone !== "" &&
                                    address !== null && address !== "") {

                                    setLoading(true);
                                    var result = await userServiceInstance.update(user.id, name, phone, address);
                                    setLoading(false);
                                    user.name = name;
                                    user.phone = phone;
                                    user.address = address;
                                    myConst.UtilsInstance.setUser(user)

                                    ShowDialog(dialog, setDialog, "Información", "Información actualizada", async () => {
                                        navigate(-1);
                                    }, result);

                                } else {
                                    setDialog({ ...dialog, description: "Toda la información es requerida", show: true })
                                }

                            }}>Actualizar</button>

                        <h3 className={styles.createAccountText} onClick={() => {
                            navigate(-1);
                        }}>Volver</h3>

                    </div>
                </div>
            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, function: async () => { } })
                }} />) : (<></>)
            }
            <img className="appIconBackground" src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />
        </div>
    );


}

export default UpdateUserPage;