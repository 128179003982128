import styles from "./PetAdoptionCreationPage.module.scss";
import React, { useEffect, useState, useRef } from "react";
import { Route, useNavigate, useLocation } from 'react-router-dom';

import addressServiceInstance from "api/addressServide";
import cdnServiceInstance from "api/cdnService";
import animalAdoptionServiceInstance from "api/animalAdoptionService";

import Menu from "UI/components/Menu/Menu";
import Loading from "UI/components/Loading/Loading";
import InputIcon from "UI/components/InputIcon/InputIcon";
import InputImagePicker from "UI/components/InputImagePicker/InputImagePicker";
import DropdownInput from "UI/components/Dropdown/DropdownInput";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";

import ImageGallery from "react-image-gallery";
import imageCompression from 'browser-image-compression';

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';


const PetAdoptionCreationPage = () => {
    const [initImageArray, setInitImageArray] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [sex, setSex] = useState('M');
    const [phone, setPhone] = useState("");

    const [provincia, setProvincia] = useState('');
    const [provinciaArray, setProvinciaArray] = useState([{ name: 'Todas', code: 'NA' }]);
    const [canton, setCanton] = useState('');
    const [cantonArray, setCantonArray] = useState([]);
    const [distrito, setDistrito] = useState('');
    const [distritoArray, setDistritoArray] = useState([]);

    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", iconType: "info", function: async () => { } });
    const navigate = useNavigate();
    const location = useLocation();

    const galleryRef = useRef(null);

    useEffect(async () => {window.scrollTo(0, 0);
        if (myConst.UtilsInstance.getUser() === null) { navigate('/LoginPage', { replace: true }); } else {
            setLoading(true);
            var resultProvince = await addressServiceInstance.provincia();
            if (resultProvince.code == 200) {
                setProvinciaArray(resultProvince.data);
            }
            if (location.state != null) {
                setTitle(location.state.item.title);
                setDescription(location.state.item.description);
                setSex(location.state.item.sex);
                setPhone(location.state.item.phone);
                setSelectedImage(myConst.UtilsInstance.gallerySize(location.state.item.images));
                setInitImageArray(location.state.item.images);
                setProvincia(location.state.item.codigo_provincia);
                setCanton(location.state.item.codigo_canton);
                setDistrito(location.state.item.codigo_distrito);
                setCantonArray((await addressServiceInstance.canton(location.state.item.codigo_provincia)).data);
                setDistritoArray((await addressServiceInstance.distrito(location.state.item.codigo_canton)).data);
            }
            setLoading(false);
        }
    }, []);

    const sexArray = [{ name: 'Macho', code: 'M' }, { name: 'Hembra', code: 'H' }];

    const uploadPicture = async (e) => {
        if (e.target.files.length > 0) {
            setLoading(true);
            try {
                const options = { maxSizeMB: 0.5, maxWidthOrHeight: 1000, useWebWorker: true }
                const compressedFile = await imageCompression(e.target.files[0], options);
                setSelectedImage([...selectedImage, ...[
                    {
                        originalHeight: 300,
                        thumbnailHeight: 50,
                        original: URL.createObjectURL(compressedFile),
                        thumbnail: URL.createObjectURL(compressedFile),
                        file: compressedFile
                    }
                ]]);
            } catch (error) {
                setSelectedImage([...selectedImage, ...[
                    {
                        originalHeight: 300,
                        thumbnailHeight: 50,
                        original: URL.createObjectURL(e.target.files[0]),
                        thumbnail: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0]
                    }
                ]]);
            }

            e.target.value = null;
            setLoading(false);
        }
    };

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

                <Menu close={true} />

                <div className={styles.mainContainerInside}>

                    {location.state != null ? (
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: "center", marginTop: '10px',
                            marginBottom: '20px'
                        }}>

                            <i className={`fa-solid fa-trash ${styles.deleteIcon}`} onClick={async () => {
                                setLoading(true);
                                var result = await animalAdoptionServiceInstance.delete(location.state.item.id, initImageArray);
                                if (result.code != 200) {
                                    setDialog({ ...dialog, description: "Problemas al eliminar la publicación, inténtelo más tarde", show: true, iconType: "error" })
                                } else {
                                    navigate(-1, { state: { showMy: true }, replace: true });
                                }
                                setLoading(false);
                            }}></i>

                        </div>
                    ) : (<></>)}

                    <h2 className={styles.loginTitle}>{location.state != null ? "Modificar publicación" : "Nueva publicación"}</h2>

                    {
                        selectedImage.length > 0 ?
                            (<div className={styles.gallery}>
                                <div className={styles.petImageDelete} onClick={async () => {
                                    setSelectedImage([
                                        ...selectedImage.slice(0, galleryRef.current.getCurrentIndex()),
                                        ...selectedImage.slice(galleryRef.current.getCurrentIndex() + 1)
                                    ]);
                                }}>
                                    <i className="fa-solid fa-xmark"></i>
                                </div>
                                <ImageGallery ref={galleryRef} showPlayButton={false} items={selectedImage} />

                            </div>) :
                            (<></>)
                    }

                    <h3 className={styles.hintMultiplePictures}>Puede añadir múltiples fotografías</h3>

                    <InputImagePicker other={selectedImage.length > 0} onChange={async (e) => {
                        await uploadPicture(e);
                    }} />

                    <InputIcon title="Título:" type="text" icon="fa-solid fa-font"
                        value={title} onChange={(e) => { setTitle(e.target.value) }} />

                    <InputIcon title="Descripción:" type="text" icon="fa-solid fa-comment" multiLine={true} max={500}
                        value={description} onChange={(e) => { setDescription(e.target.value) }} />

                    <DropdownInput title="Sexo:" icon="fa-solid fa-venus-mars" value={sex} onChange={(e) => setSex(e.target.value)}
                        options={sexArray} label="sex" />

                    <InputIcon title="Teléfono:" type="text" icon="fa-solid fa-phone"
                        value={phone} onChange={(e) => { setPhone(e.target.value) }} />

                    <DropdownInput title="Provincia:" icon="fa-solid fa-globe" value={provincia} onChange={async (e) => {
                        setLoading(true);
                        setProvincia(e.target.value);
                        setCanton('');
                        setDistrito('');
                        setCantonArray((await addressServiceInstance.canton(e.target.value)).data);
                        setDistritoArray([]);
                        setLoading(false);
                    }} options={provinciaArray} label="provincia" />

                    {cantonArray.length > 1 ? (
                        <DropdownInput title="Cantón:" icon="fa-solid fa-globe" value={canton} onChange={async (e) => {
                            setLoading(true);
                            setCanton(e.target.value);
                            setDistrito('');
                            setDistritoArray((await addressServiceInstance.distrito(e.target.value)).data);
                            setLoading(false);
                        }} options={cantonArray} label="canton" />
                    ) : (<></>)}

                    {distritoArray.length > 1 ? (
                        <DropdownInput title="Distrito:" icon="fa-solid fa-globe" value={distrito} onChange={(e) => {
                            setDistrito(e.target.value);
                        }} options={distritoArray} label="distrito" />
                    ) : (<></>)}


                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {

                                if (
                                    phone &&
                                    description &&
                                    title &&
                                    sex &&
                                    provincia &&
                                    canton &&
                                    distrito) {

                                    setLoading(true);
                                    var result;

                                    //upload to cdn the preview images
                                    if (selectedImage.length > 0) {
                                        for (let i = 0; i < selectedImage.length; i++) {
                                            if (!selectedImage[i].original.includes(myConst.baseUrlCdn)) {
                                                var resultUploadImage = await cdnServiceInstance.create(selectedImage[i].file);

                                                if (resultUploadImage.code != 200) {
                                                    setDialog({ ...dialog, description: "Problemas al guardar la imagen, inténtelo más tarde", show: true, iconType: "error" })
                                                } else {
                                                    setSelectedImage((e) => {
                                                        var imageArray = e;
                                                        imageArray[i].original = myConst.baseUrlCdn + resultUploadImage.data;
                                                        return imageArray;
                                                    });
                                                }
                                            }
                                        }
                                    }


                                    if (location.state == null) {
                                        result = await animalAdoptionServiceInstance.create(phone, description, title, sex, provincia, canton, distrito, myConst.UtilsInstance.getUser().id, myConst.UtilsInstance.gallerySizeToPathList(selectedImage));
                                        ShowDialog(dialog, setDialog, "Guardado", "Publicación guardada", async () => {
                                           // show my is not in use
                                            navigate(-1, { state: { showMy: true }, replace: true });
                                        }, result);

                                    } else {
                                        result = await animalAdoptionServiceInstance.update(phone, description, title, sex, provincia, canton, distrito, location.state.item.id, myConst.UtilsInstance.getUser().id, myConst.UtilsInstance.gallerySizeToPathList(selectedImage), initImageArray);
                                        ShowDialog(dialog, setDialog, "Actualizado", "Publicación actualizada", async () => {
                                             // show my is not in use
                                            navigate(-1, { state: { showMy: true }, replace: true });
                                        }, result);

                                    }
                                    setLoading(false);

                                } else {
                                    setDialog({ ...dialog, description: "Todos los campos de texto son requeridos", show: true, iconType: "error" })
                                }


                            }}>Guardar</button>

                        <h3 className={styles.createAccountText} onClick={() => {
                             // show my is not in use
                            navigate(-1, { replace: false });
                        }}>Volver</h3>

                    </div>
                </div>
            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, iconType: "info", function: async () => { } })
                }} />) : (<></>)
            }
            <img className="appIconBackground" src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />
        </div>
    );


}

export default PetAdoptionCreationPage;