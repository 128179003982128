import styles from "./AboutUsPage.module.scss";

import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from "react";

import appIconImage from 'UI/assets/images/ZaguaTico_Turquesa.svg';
import appIconMoncakeImage from 'UI/assets/images/BigMPrimary.png';
import fLogo from 'UI/assets/images/FLogo_Blanco.svg';

import Loading from 'UI/components/Loading/Loading';
import AlertDialog, { ShowDialog } from 'UI/components/AlertDialog/AlertDialog';
import Menu from 'UI/components/Menu/Menu';

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';

const CodeDetailPage = () => {
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", function: async () => { } });
    const navigate = useNavigate();
    const location = useLocation();

    var user = myConst.UtilsInstance.getUser();

    useEffect(() => {window.scrollTo(0, 0);

    }, []);

    const elementRef = useRef(null);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

                <Menu close={true} />

                <h2 className={styles.titleWhiteFirst}>Acerca de nosotros</h2>

                <div className={styles.mainContainerInside}>

                    <img className={styles.appIconDog} src={appIconImage} />

                    <i className={`${styles.appIconPlus} fa-solid fa-plus`}></i>

                    <img className={styles.appIcon} src={appIconMoncakeImage} />

                </div>


                <div className={styles.mainContainerInsideNoBackground}>
                    <h2 className={styles.titleWhite}>Nuestra organización y patrocinadores</h2>

                    <h4 className={styles.titleWhiteH3}>Plataforma FindMeCR</h4>
                    <p>Es una plataforma sin fines de lucro creada con el deseo de ayudar a proteger a nuestras mascotas con el uso del código QR, además, cuenta con una sección que facilita la adopción de mascotas que buscan un nuevo hogar.</p>

                    <img src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />

                    <h4 className={styles.titleWhiteH3}>Desarrollo de Software Moncake</h4>
                    <p>Moncake es una empresa de desarrollo de software a la medida, la cuál cuenta con experiencia en el desarrollo web y móvil.</p>

                    <a href="https://www.facebook.com/profile.php?id=61555735427171"><img className={styles.moncake} src={require('UI/assets/images/iconMoncake.png').default} /></a>

                    <h4 className={styles.titleWhiteH3}>Nuestra diseñadora</h4>
                    <p>Fabiola Vargas Álvarez, licenciada en diseño gráfico, fue una de las patrocinadoras más importantes de este proyecto ya que estuvo encargada de todo el sector de marketing y diseño.</p>

                    <a href="https://fabiolavar.wixsite.com/fabiola-vargas"><img src={fLogo} /></a>

                </div>
            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, function: async () => { } })
                }} />) : (<></>)
            }
        </div>
    );


}

export default CodeDetailPage;