import styles from "./loginPage.module.scss";

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import userServiceInstance from "api/userService";

import loginHeaderImage from "UI/assets/images/welcomeLogo.png";

import WelcomePage from "UI/Views/generalView/welcomePage/WelcomePage";

import Loading from "UI/components/Loading/Loading";
import InputIcon from "UI/components/InputIcon/InputIcon";
import AlertDialog from "UI/components/AlertDialog/AlertDialog";
import PowerBy from "UI/components/powerBy/PowerBy";

import * as myConst from 'common/common';



const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: "Atención", description: "", iconType: "info" });
    const [showLogin, setShowLogin] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(async () => {window.scrollTo(0, 0);
        if (myConst.UtilsInstance.getUserCookie()) {
            var user = JSON.parse(myConst.UtilsInstance.getUserCookie());
            setLoading(true);
            var result = await userServiceInstance.login(user.email, user.password);
            setLoading(false);
            if (result.code == 200) {
                myConst.UtilsInstance.setUser(result.data)
                navigate('/', { replace: true });
            }
        }

        if (location.state != null && location.state.showLogin == true) {
            setShowLogin(true);
        }
    }, []);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>
                {!showLogin ? (

                    <div className={styles.loginButtonHeaderColumn}>
                        <img className={styles.appNameTitleIcon} src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />
                        <div className={styles.loginButtonHeader}>
                            <div className={styles.welcomeTextContainer}>
                                <h2 >Bienvenido a</h2>
                                <h1>FindMeCR</h1>
                            </div>
                            <button
                                className={styles.whiteButton}
                                onClick={async () => {
                                    setShowLogin(true);
                                }}>Ingresar <i className="fa-solid fa-right-to-bracket"></i></button>
                        </div>
                    </div>
                ) : (<></>)}

                {showLogin ? (<><div className={styles.mainContainerInside}>

                    <h3 className={styles.invitedText} onClick={() => {
                        navigate('/PetAdoptionDashboardPage', { replace: false });
                    }}>Ingresar como invitado  <i className={`fa-solid fa-chevron-right`}></i></h3>

                    <img src={loginHeaderImage} className={styles.loginHeaderImage} alt="Company Logo" />

                    <h2 className={styles.loginTitle}>Bienvenido</h2>

                    <InputIcon title="Correo electrónico:" type="email" icon="fa-solid fa-envelope" value={email} onChange={(inputValue) => {
                        setEmail(inputValue.target.value)
                    }} />

                    <InputIcon title="Contraseña:" type="password" icon="fa-solid fa-lock" value={password} onChange={(inputValue) => {
                        setPassword(inputValue.target.value)
                    }} />

                    <div><p className={styles.forgotPassword} onClick={() => {
                        navigate('/PasswordRecoveryPage', { replace: false });
                    }}>¿Olvidaste tu contraseña?</p></div>

                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {


                                if (
                                    email !== null && email !== "" &&
                                    password !== null && password !== "") {
                                    setLoading(true);
                                    var result = await userServiceInstance.login(email, password);
                                    setLoading(false);
                                    if (result.code != 200) {
                                        setDialog({ ...dialog, description: result.data, show: true, iconType: "error" })
                                    } else {
                                        myConst.UtilsInstance.setUser(result.data)
                                        navigate('/', { replace: true });
                                    }
                                } else {
                                    setDialog({ ...dialog, description: "Email o contraseña en blanco", show: true, iconType: "error" })
                                }

                            }}>Inicia sesión</button>
                        <h3 className={styles.createAccountText} onClick={() => {
                            navigate('/CreateUserPage', { replace: false });
                        }}>Crear cuenta nueva</h3>
                    </div>

                </div>
                    <PowerBy /> </>) : (<></>)}
                {!showLogin ? (<WelcomePage />) : (<></>)}
            </div>

            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    setDialog({ ...dialog, show: false, title: "Atención", iconType: "info" })
                }} />) : (<></>)
            }

        </div>
    );


}

export default LoginPage;

