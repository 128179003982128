import styles from "./PetCreationPage.module.scss";
import React, { useEffect, useState, useRef } from "react";
import { Route, useNavigate, useLocation } from 'react-router-dom';

import animalServiceInstance from "api/animalService";
import cdnServiceInstance from "api/cdnService";

import Menu from "UI/components/Menu/Menu";
import Loading from "UI/components/Loading/Loading";
import InputIcon from "UI/components/InputIcon/InputIcon";
import InputImagePicker from "UI/components/InputImagePicker/InputImagePicker";
import DropdownInput from "UI/components/Dropdown/DropdownInput";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";

import imageCompression from 'browser-image-compression';

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';


const PetCreationPage = () => {
    const [lastImageForDelete, setLastImageForDelete] = useState("");
    const [selectedImage, setSelectedImage] = useState("");
    const [name, setName] = useState("");
    const [specialNeeds, setSpecialNeeds] = useState("");
    const [castrated, setCastrated] = useState(1);
    const [sex, setSex] = useState('M');
    const [race, setRace] = useState("");

    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", iconType: "info", function: async () => { } });
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {window.scrollTo(0, 0);
        if (myConst.UtilsInstance.getUser() === null) { navigate('/LoginPage', { replace: true }); } else {
            if (location.state != null) {
                setName(location.state.item.name);
                setSpecialNeeds(location.state.item.special_needs);
                setCastrated(location.state.item.castrated);
                setSex(location.state.item.sex);
                setRace(location.state.item.race);
                setSelectedImage(location.state.item.image);
            }
        }
    }, []);

    const sexArray = [{ name: 'Macho', code: 'M' }, { name: 'Hembra', code: 'H' }];
    const castratedArray = [{ name: 'Si', code: 1 }, { name: 'No', code: 0 }];

    const uploadPicture = async (e) => {
        if (e.target.files.length > 0) {
            setLoading(true);
            try {
                const options = { maxSizeMB: 0.5, maxWidthOrHeight: 1000, useWebWorker: true }
                const compressedFile = await imageCompression(e.target.files[0], options);

                var result = await cdnServiceInstance.create(compressedFile);
                if (result.code != 200) {
                    setDialog({ ...dialog, description: "Problemas al guardar la imagen, inténtelo más tarde", show: true, iconType: "error" })
                } else {
                    setSelectedImage(result.data);
                }
            } catch (error) {
                var result = await cdnServiceInstance.create(e.target.files[0]);
                if (result.code != 200) {
                    setDialog({ ...dialog, description: "Problemas al guardar la imagen, inténtelo más tarde", show: true, iconType: "error" })
                } else {
                    setSelectedImage(result.data);
                }
            }
            setLoading(false);
        }
    };

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

                <Menu close={true} />

                <div className={styles.mainContainerInside}>

                    {location.state != null ? (
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: "center", marginTop: '10px',
                            marginBottom: '20px'
                        }}>

                            <i className={`fa-solid fa-trash ${styles.deleteIcon}`} onClick={async () => {
                                setLoading(true);
                                var result = await animalServiceInstance.delete(location.state.item.id);
                                if (result.code != 200) {
                                    setDialog({ ...dialog, description: "Problemas al eliminar la mascota, inténtelo más tarde", show: true, iconType: "error" })
                                } else {
                                    navigate('/', { replace: false });
                                }
                                setLoading(false);
                            }}></i>

                            <div className={styles.petsCellQrContainer} onClick={() => {
                                var data = location.state.item;
                                navigate('/CodeDetailPage', { state: { data }, replace: false });
                            }}>
                                <p>Ver código</p>
                                <i className="fa-solid fa-qrcode"></i>
                            </div>
                        </div>
                    ) : (<></>)}

                    <h2 className={styles.loginTitle}>{location.state != null ? "Modificar mascota" : "Nueva mascota"}</h2>

                    {
                        selectedImage != "" && selectedImage != null ?
                            (<div className={styles.petImageContainer}>
                                <div className={styles.petImageDelete} onClick={async () => {
                                    if (location.state == null || lastImageForDelete) {
                                        setLoading(true);
                                        var result = await cdnServiceInstance.delete(selectedImage);
                                        if (result.code != 200) {
                                            setDialog({ ...dialog, description: "Problemas al eliminar la imagen, inténtelo más tarde", show: true, iconType: "error" })
                                        } else {
                                            setSelectedImage("");
                                        }
                                        setLoading(false);
                                    } else {
                                        setLastImageForDelete(selectedImage);
                                        setSelectedImage("");
                                    }
                                }}>
                                    <i className="fa-solid fa-xmark"></i>
                                </div>
                                <img id="dogImage" className={styles.petImage} src={myConst.baseUrlCdn + selectedImage} onClick={() => {
                                    document.getElementById('dogImage')?.requestFullscreen()
                                }} />
                            </div>) :
                            (<InputImagePicker onChange={async (e) => {
                                await uploadPicture(e);
                            }} />)
                    }

                    <InputIcon title="Nombre:" type="text" icon="fa-solid fa-signature"
                        value={name} onChange={(e) => { setName(e.target.value) }} />

                    <InputIcon title="Raza (Opcional):" type="text" icon="fa-solid fa-dog"
                        value={race} onChange={(e) => { setRace(e.target.value) }} />

                    <DropdownInput title="Castrado:" icon="fa-solid fa-scissors" value={castrated} onChange={(e) => setCastrated(e.target.value)}
                        options={castratedArray} label="castrated" />

                    <DropdownInput title="Sexo:" icon="fa-solid fa-venus-mars" value={sex} onChange={(e) => setSex(e.target.value)}
                        options={sexArray} label="sex" />

                    <InputIcon title="Necesidades especiales (Opcional):" type="text" icon="fa-solid fa-stethoscope" multiLine={true} max={500}
                        value={specialNeeds} onChange={(e) => { setSpecialNeeds(e.target.value) }} />


                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {

                                if (
                                    name !== null && name !== "") {

                                    setLoading(true);
                                    var result;
                                    if (location.state == null) {
                                        result = await animalServiceInstance.create(name, myConst.UtilsInstance.getUser().id, 0, specialNeeds, castrated, sex, race, selectedImage);

                                        var data = result.data;
                                        ShowDialog(dialog, setDialog, "Guardado", "Mascota guardada", async () => {
                                            navigate('/CodeDetailPage', { state: { data }, replace: true });
                                        }, result);

                                    } else {

                                        if (lastImageForDelete != "") { await cdnServiceInstance.delete(lastImageForDelete); }

                                        result = await animalServiceInstance.update(name, myConst.UtilsInstance.getUser().id, 0, specialNeeds, castrated, sex, race, selectedImage, location.state.item.id);
                                        ShowDialog(dialog, setDialog, "Actualizado", "Mascota actualizada", async () => {
                                            navigate('/', { replace: true });
                                        }, result);

                                    }
                                    setLoading(false);

                                } else {
                                    setDialog({ ...dialog, description: "El nombre es requerido", show: true, iconType: "error" })
                                }


                            }}>Guardar</button>

                        <h3 className={styles.createAccountText} onClick={() => {
                            navigate('/', { replace: false });
                        }}>Volver</h3>

                    </div>
                </div>
            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, iconType: "info", function: async () => { } })
                }} />) : (<></>)
            }
            <img className="appIconBackground" src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />
        </div>
    );


}

export default PetCreationPage;