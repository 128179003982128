import styles from "./PowerBy.module.scss";
import React from "react";
import appIconMoncakeImage from "../../assets/images/poweredBy.png";

const PowerBy = () => {
    return (

        <div className={styles.out}>
            <a className={styles.a} href="https://www.facebook.com/profile.php?id=61555735427171"><img className={styles.image} src={appIconMoncakeImage} /></a>
        </div>
    );

}

export default PowerBy;