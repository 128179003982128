import styles from "./ShelterDashboardPage.module.scss";

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import animalAdoptionServiceInstance from "api/animalAdoptionService";
import shelterServiceInstance from "api/shelterService";
import userServiceInstance from "api/userService";

import Loading from "UI/components/Loading/Loading";
import SwitchComponent from "UI/components/SwitchComponent/SwitchComponent";
import Menu from "UI/components/Menu/Menu";
import ShareDialog from "UI/components/shareDialog/ShareDialog";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";

import ImageGallery from "react-image-gallery";

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';


const ShelterDashboardPage = () => {

    const [user, setUser] = useState(myConst.UtilsInstance.getUser());
    const [loading, setLoading] = useState(false);
    const [shelterActive, setShelterActive] = useState(user ? (user.is_shelter_house == 1 ? true : false) : false);
    const [animals, setAnimals] = useState([]);
    const [page, setPage] = useState(0);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", iconType: "info", function: async () => { } });

    const refShareDialog = useRef(null);

    const params = useParams();
    const navigate = useNavigate();


    useEffect(async () => {
        window.scrollTo(0, 0);

        setLoading(true);

        if (await myConst.UtilsInstance.autoLogin() && !params.id ) { navigate('/LoginPage', { replace: true }); } else {

            var searchedUser;

            if (user && params.id && params.id == user.id) {
                params.id = null;
            }

            if (params.id) {
                setShelterActive(true);
                searchedUser = await userServiceInstance.getUserId(params.id)
                setUser(searchedUser.data);
            }

            var result = await animalAdoptionServiceInstance.getAll('NA', 'NA', 'NA', 'NA', searchedUser ? searchedUser.data.id : user.id, 0);
            if (result.code == 200) {
                setAnimals(result.data);
                if (result.data.length < 10) {
                    setPage(-1);
                }
            }

        }

        setLoading(false);

    }, []);

    const refSwitchShelter = useRef(null);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }


            <div className={styles.mainContainer}>

                <Menu onNoRegisterAction={() => {
                    setDialog({
                        ...dialog, description: "Debe iniciar sesión para esta acción", show: true, iconType: "info", function: async () => {
                            navigate('/LoginPage', { replace: false, state: { showLogin: true } });
                        }
                    })
                }
                } />

                <div className={styles.mainContainerInside}>

                    {params.id ? (<></>) : (
                        <div className={styles.enableShelterHouseSwitch} onClick={async () => {
                            if (!user.is_shelter_house) {
                                navigate('/ShelterCreationPage', { replace: false });
                            } else {
                                setLoading(true);
                                var result = await shelterServiceInstance.inactive(user.id);
                                if (result.code == 200) {
                                    var userVarHelp = user;
                                    userVarHelp.is_shelter_house = false;
                                    myConst.UtilsInstance.setUser(userVarHelp);
                                    setShelterActive(false);
                                    refSwitchShelter.current.enableSwitch();

                                }
                                setLoading(false);
                            }

                        }}>
                            {shelterActive ? (<p>Activo</p>) : (<p>Activar Casa cuna</p>)}
                            <SwitchComponent ref={refSwitchShelter} active={shelterActive} />
                        </div>
                    )}


                    {!shelterActive ? (
                        <div className={styles.enableShelterHouse}>

                            <i className="fa-solid fa-house-medical fa-2xs"></i>

                            <h1 className={styles.sectionTitle}>Beneficios de ser casa cuna</h1>
                            <p className={styles.sectionSubTitle}>Activa esta funcionalidad para que los usuarios puedan ver todas tus publicaciones, la información de tu casa cuna y puedas compartir tu perfil</p>

                        </div>
                    ) :

                        user ? (
                            <div className={styles.shelterActiveContainer} >
                                {
                                    params.id ? (<></>) : (
                                        <i className={`fa-solid fa-pencil ${styles.editShelter}`} onClick={() => {
                                            navigate('/ShelterCreationPage', { replace: false });
                                        }}></i>
                                    )
                                }
                                < div className={styles.shelterActiveContainerHeader} >

                                    <div className={styles.imageContainer}>
                                        <img src={myConst.baseUrlCdn + user.shelter_picture} />
                                    </div>

                                    <div className={styles.shelterTexts}>
                                        <h1>{user.shelter_name}</h1>
                                        <h3>{user.shelter_description}</h3>

                                        <div className={styles.address}>
                                            <h3>{user.shelter_provincia_name}</h3>
                                            <h3>{user.shelter_canton_name}</h3>
                                            <h3>{user.shelter_distrito_name}</h3>
                                        </div>


                                        <h3 className={styles.phone}>{user.shelter_phone}</h3>

                                    </div>

                                </div>

                            </div>
                        ) : (<></>)
                    }

                    {shelterActive && user ? (<>

                        <div className={styles.shareContainer} onClick={() => { refShareDialog.current.showDialog(myConst.appUrl + "ShelterDetailPage/" + user.id); }}>
                            <p>Compartir</p>
                            <i className="fa-solid fa-share-nodes"></i>
                        </div>

                        <div className={styles.lineHeader}></div>


                        {params.id ? (<></>) : (

                            <div className={styles.addPetButton} onClick={() => {
                                if (user) { navigate('/PetAdoptionCreationPage', { replace: false }); } else {
                                    setDialog({
                                        ...dialog, description: "Debe iniciar sesión para esta acción", show: true, iconType: "info", function: async () => {
                                            navigate('/LoginPage', { replace: false, state: { showLogin: true } });
                                        }
                                    })
                                }

                            }}>
                                <i className="fa-solid fa-plus"></i>
                            </div>
                        )}

                        <h1 className={styles.sectionTitle}>Mis mascotas en adopción</h1>

                        {
                            animals.length > 0 ? (

                                <div className={styles.petsContainer}>
                                    {
                                        animals.map((item) => (

                                            <div className={styles.petsCellContainer} key={item.id}>

                                                <div className={styles.postTitleContainer}>
                                                    <h5 className={styles.postTitle}>{myConst.UtilsInstance.camelize(item.title)}</h5>

                                                    {user && item.idUser == user.id && !params.id ? (<i className="fa-solid fa-pencil" onClick={() => {
                                                        navigate('/PetAdoptionCreationPage', { state: { item }, replace: false });
                                                    }}></i>) : (<></>)}

                                                </div>


                                                {item.images.length !== 0 ? (
                                                    <div className={styles.gallery}>
                                                        <ImageGallery lazyload={true} showPlayButton={false} showThumbnails={false} items={myConst.UtilsInstance.gallery(item.images)} />
                                                    </div>
                                                ) : (<></>)}

                                                <p className={styles.postDescription}>{item.description}</p>

                                                <div className={styles.petsCellTagsContainer}>
                                                    <i className="fa-solid fa-venus-mars"></i>
                                                    <p>{item.sex === "M" ? "Macho" : "Hembra"}</p>
                                                </div>

                                                <div className={styles.petsCellTagsContainer}>
                                                    <i className="fa-solid fa-phone"></i>
                                                    <p>{item.phone}</p>
                                                </div>

                                                <div className={styles.petsCellTagsContainer}>
                                                    <i className="fa-solid fa-earth-americas"></i>
                                                    <p>{item.nombre_provincia + " " + item.nombre_canton + " " + item.nombre_distrito}</p>
                                                </div>


                                                <div className={styles.cardIconContainers}>

                                                    <div className={styles.iconText} onClick={() => {
                                                        navigate('/PetAdoptionDetailPage/' + item.id, { replace: false });
                                                    }}>
                                                        <i className="fa-solid fa-comment"></i>
                                                        <p>{item.comments}</p>
                                                    </div>

                                                    <div className={styles.iconText} onClick={() => { refShareDialog.current.showDialog(myConst.appUrl + "PetAdoptionDetailPage/" + item.id); }}>
                                                        <p>Compartir</p>
                                                        <i className="fa-solid fa-share-nodes"></i>
                                                    </div>
                                                </div>

                                                <div className={styles.line}>

                                                </div>

                                                <div className={styles.comment} onClick={() => {
                                                    navigate('/PetAdoptionDetailPage/' + item.id, { replace: false });
                                                }}>
                                                    <i className="fa-solid fa-comments"></i>
                                                    <p>Comentar</p>
                                                </div>


                                            </div>

                                        ))
                                    }

                                </div>

                            ) : (
                                <div className={styles.emptyContainer}>
                                    <i className="fa-regular fa-rectangle-list"></i>
                                    <h2>Aún no hay publicaciones</h2>
                                </div>
                            )
                        }

                        {
                            page != -1 ? (
                                <div className={styles.loadMoreContainer} onClick={async () => {
                                    setLoading(true);
                                    var result = await animalAdoptionServiceInstance.getAll('NA', 'NA', 'NA', 'NA', user.id, page + 1);
                                    if (result.code == 200) {
                                        if (result.data.length < 10) {
                                            setPage(-1);
                                        } else {
                                            setPage(page + 1);
                                        }
                                        setAnimals([...animals, ...result.data]);
                                    }
                                    setLoading(false);
                                }}>
                                    <h3>Cargar más elementos</h3>
                                    <i className={`fa-solid fa-angle-down`}></i>
                                </div>
                            ) : (<></>)
                        }


                    </>) : (<></>)}

                </div>


            </div >

            <ShareDialog ref={refShareDialog} />

            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, iconType: "info", function: async () => { } })
                }} />) : (<></>)
            }

            <img className="appIconBackground" src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />
        </div >
    );


}

export function ShelterDashboardDetailPage() {
    return ShelterDashboardPage();
}

export default ShelterDashboardPage;