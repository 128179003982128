import styles from "./InputIcon.module.scss";
import React, { useRef, useState } from "react";

const InputIcon = (props) => {

    const [showPassword, setShowPassword] = useState(props.initShowPassword ? true : false);

    var max = 255;
    if (props.max != null) {
        max = props.max;
    }

    return (
        <div className={styles.inputOutside}>
            <h3 className={styles.inputTitle}>{props.title}</h3>
            <div className={styles.inputText}>
                {props.icon !== null ? (<i className={`${props.icon} ${styles.iconColor}`}></i>) : (<></>)}

                {!props.multiLine ? (
                    <input
                        autoCorrect="off"
                        autoCapitalize="off"
                        type={showPassword ? "text" : props.type}
                        value={props.value}
                        onChange={props.onChange}
                        maxLength={`${max}`}
                    >
                    </input>
                ) : (
                    <textarea
                        rows={!props.rows ? "3" : props.rows}
                        type={props.type}
                        value={props.value}
                        onChange={props.onChange}
                        maxLength={`${max}`}
                    ></textarea>
                )}

                {props.type == "password" ?
                    (<i onClick={() => {
                        setShowPassword(!showPassword);
                    }} className={`${showPassword ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"} ${styles.iconColor}`}></i>
                    ) : (<></>)}
            </div>
        </div>
    );

}

export default InputIcon;