import * as myConst from '../common/common.js';

class CommentsService {

    create = async (type, idUser, idElement, text) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ type: type, idUser: idUser, idElement: idElement, text: text})
            };

            const response = await fetch(myConst.baseUrl + 'comments/add', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    getAll = async (type,idElement) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: myConst.headersBase,
            };
            const response = await fetch((myConst.baseUrl + 'comments/getAll?' + new URLSearchParams({
                type: type,
                idElement: idElement,
            })), requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    delete = async (id) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ id: id })
            };

            const response = await fetch(myConst.baseUrl + 'comments/delete', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

}

const commentsServiceInstance = new CommentsService();
export default commentsServiceInstance;