import styles from "./DropdownInput.module.scss";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';

const DropdownInput = (props) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <div className={styles.inputOutside}>
            <h3 className={styles.inputTitle}>{props.title}</h3>
            <div className={styles.inputText}>
                {props.icon !== null ? (<i className={`${props.icon} ${styles.iconColor}`}></i>) : (<></>)}
                <Select
                    labelId={props.label}
                    id={props.label}
                    value={props.value}
                    label=""
                    onChange={props.onChange}
                    className={styles.Select}
                    sx={{
                        margin: '0px',
                        padding: '0px !important',
                        fontSize: !matches ? '16px' : '22px',
                        fontWeight: '300',
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,
                        },
                        '& .MuiSelect-select': {
                            paddingLeft: '10px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                        }
                    }}
                >

                    {
                        props.options.map((item) => {
                            return <MenuItem sx={{
                                margin: '0px',
                                fontSize: !matches ? '16px' : '22px',
                                fontWeight: '300',

                            }} value={item.code} key={item.code}>{item.name}</MenuItem>
                        })
                    }
                </Select>
            </div>
        </div>
    );

}

export default DropdownInput;