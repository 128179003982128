import Cookies from 'js-cookie';
import userServiceInstance from "../api/userService";

export const appUrl = process.env.REACT_APP_URL
export const baseUrl = process.env.REACT_APP_API //"http://localhost:3001/"
export const baseUrlCdn = baseUrl + "uploads/"
export const findUrl = process.env.REACT_APP_FIND
export const generalError = { code: 400, data: "Error inesperado, inténtelo más tarde" }
export const headersBase = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*','mon-token': `${process.env.REACT_APP_TOKEN}` }
export const headersBaseFormData = { 'Access-Control-Allow-Origin': '*','mon-token': `${process.env.REACT_APP_TOKEN}` }

class Utils {

    checkEmail = (email) => {
        try {
            const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

            if (email.match(isValidEmail)) {
                return true;
            }
            else {
                return false;
            }
        } catch (error) {
            console.error("Error", error);
            throw error;
        }
    };

    autoLogin = async () => {
        if (this.getUser() === null) { 
            if (this.getUserCookie()) { 
                var user = JSON.parse(this.getUserCookie());
                var result = await userServiceInstance.login(user.email, user.password);
                if (result.code == 200) {
                    this.setUser(result.data)
                }else{
                    return true
                }
            }else{
                return true
            }
         }
         return false;
    }

    getUserCookie = () => {
        return Cookies.get('user');
    }

    cleanUserCookie = () => {
        Cookies.remove('user');
    }

    getUser = () => {
        return JSON.parse(localStorage.getItem('user'));
    };

    setUser = (user) => {
        localStorage.setItem('user', JSON.stringify(user));

        if (user == null) {
            Cookies.remove('user');
        } else {
            Cookies.set('user', JSON.stringify(user), { expires: 1000 });
        }

    };

    camelize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);;
    }

    gallery = (imageList) => {
        var returnList = [];
        imageList.forEach(element => {
            returnList.push({
                original: baseUrlCdn + element.path,
                thumbnail: baseUrlCdn + element.path,
            });
        });
        return returnList;
    }

    gallerySize = (imageList) => {
        var returnList = [];
        imageList.forEach(element => {
            returnList.push({
                originalHeight: 300,
                thumbnailHeight: 50,
                original: baseUrlCdn + element.path,
                thumbnail: baseUrlCdn + element.path,
            });
        });
        return returnList;
    }

    gallerySizeToPathList = (imageList) => {
        var returnList = [];
        imageList.forEach(element => {
            var removeCdn = element.original.replace(baseUrlCdn,"");
            returnList.push(removeCdn);
        });
        return returnList;
    }

}

export const UtilsInstance = new Utils();