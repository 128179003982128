import styles from "./PetAdoptionDashboardPage.module.scss";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import animalAdoptionServiceInstance from "api/animalAdoptionService";
import addressServiceInstance from "api/addressServide";

import Loading from "UI/components/Loading/Loading";
import Menu from "UI/components/Menu/Menu";
import DropdownInput from "UI/components/Dropdown/DropdownInput";
import ShareDialog from "UI/components/shareDialog/ShareDialog";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";

import ImageGallery from "react-image-gallery";

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';


const PetAdoptionDashboardPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [animals, setAnimals] = useState([]);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", iconType: "info", function: async () => { } });


    //forFilter
    const [sex, setSex] = useState('NA');
    const sexArray = [{ name: 'Todos', code: 'NA' }, { name: 'Macho', code: 'M' }, { name: 'Hembra', code: 'H' }];
    const [provincia, setProvincia] = useState('NA');
    const [provinciaArray, setProvinciaArray] = useState([{ name: 'Todas', code: 'NA' }]);
    const [canton, setCanton] = useState('NA');
    const [cantonArray, setCantonArray] = useState([]);
    const [distrito, setDistrito] = useState('NA');
    const [distritoArray, setDistritoArray] = useState([]);
    const [showOnlyMy, setShowOnlyMy] = useState(false);
    const [page, setPage] = useState(0);

    const refSearchButton = useRef(null);
    const refShareDialog = useRef(null);

    useEffect(async () => {
        window.scrollTo(0, 0);


        setLoading(true);


        //i modify the code i the adoption creation page, because that the showMy is never going to enter
        if (location.state != null && location.state.showMy == true) {
            setShowOnlyMy(true);
            var result = await animalAdoptionServiceInstance.getAll('NA', 'NA', 'NA', 'NA', myConst.UtilsInstance.getUser().id, 0);
            if (result.code == 200) {
                setAnimals(result.data);
                if (result.data.length < 10) {
                    setPage(-1);
                }
            }
        } else {
            var result = await animalAdoptionServiceInstance.getAll(sex, provincia, canton, distrito, "NA", 0);
            if (result.code == 200) {
                setAnimals(result.data);
                if (result.data.length < 10) {
                    setPage(-1);
                }
            }
        }

        var resultProvince = await addressServiceInstance.provinciaNA();
        if (resultProvince.code == 200) {
            setProvinciaArray(resultProvince.data);
        }
        setLoading(false);

    }, []);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }


            <div className={styles.mainContainer}>

                <Menu onNoRegisterAction={() => {
                    setDialog({
                        ...dialog, description: "Debe iniciar sesión para esta acción", show: true, iconType: "info", function: async () => {
                            navigate('/LoginPage', { replace: false, state: { showLogin: true } });
                        }
                    })
                }
                } />

                <div className={styles.mainContainerInside}>

                    <div onClick={() => {
                        if (myConst.UtilsInstance.getUser()) { navigate('/', { replace: false }); } else {
                            setDialog({
                                ...dialog, description: "Debe iniciar sesión para esta acción", show: true, iconType: "info", function: async () => {
                                    navigate('/LoginPage', { replace: false, state: { showLogin: true } });
                                }
                            })
                        }

                    }} className={styles.lookDogs}>
                        <h2>Mis mascotas aseguradas</h2>
                        <i className={`fa-solid fa-chevron-right ${styles.movei}`}></i>
                    </div>

                    <h1 className={styles.sectionTitle}>{showOnlyMy ? "Mis mascotas en adopción" : "Mascotas en adopción"}</h1>

                    <div className={styles.myPetsHeader} onClick={() => {
                        if (myConst.UtilsInstance.getUser()) { navigate('/PetAdoptionCreationPage', { replace: false }); } else {
                            setDialog({
                                ...dialog, description: "Debe iniciar sesión para esta acción", show: true, iconType: "info", function: async () => {
                                    navigate('/LoginPage', { replace: false, state: { showLogin: true } });
                                }
                            })
                        }

                    }}>
                        <h3>Agregar Nuevo</h3>
                        <i className="fa-solid fa-plus"></i>
                    </div>

                    <div className={styles.filterHeader}>

                        {!showOnlyMy ? (<div className={styles.myPostButton} onClick={async () => {
                            var open = !searchOpen;
                            setSearchOpen(open)
                            if (open == false) {
                                setLoading(true);
                                setPage(0);
                                setSex('NA');
                                setProvincia('NA');
                                setCanton('NA');
                                setDistrito('NA');
                                setCantonArray([]);
                                setDistritoArray([]);
                                var result = await animalAdoptionServiceInstance.getAll('NA', 'NA', 'NA', 'NA', 'NA', 0);
                                if (result.code == 200) {
                                    setAnimals(result.data);
                                    if (result.data.length < 10) {
                                        setPage(-1);
                                    }
                                }
                                setLoading(false);
                            }
                        }}>
                            <i className={`fa-solid ${searchOpen ? "fa-xmark" : "fa-magnifying-glass"}`}></i>
                        </div>) : (<></>)}

                        <div className={styles.myPostButton} onClick={async () => {

                            if (myConst.UtilsInstance.getUser()) {
                                setLoading(true);
                                setPage(0);
                                setSearchOpen(false)
                                setSex('NA');
                                setProvincia('NA');
                                setCanton('NA');
                                setDistrito('NA');
                                setCantonArray([]);
                                setDistritoArray([]);
                                setShowOnlyMy(!showOnlyMy);
                                var result = await animalAdoptionServiceInstance.getAll('NA', 'NA', 'NA', 'NA', !showOnlyMy ? myConst.UtilsInstance.getUser().id : 'NA', 0);
                                if (result.code == 200) {
                                    setAnimals((result).data);
                                    if (result.data.length < 10) {
                                        setPage(-1);
                                    }
                                }
                                setLoading(false);
                            } else {
                                setDialog({
                                    ...dialog, description: "Debe iniciar sesión para esta acción", show: true, iconType: "info", function: async () => {
                                        navigate('/LoginPage', { replace: false, state: { showLogin: true } });
                                    }
                                })
                            }

                        }}>
                            <h3>{showOnlyMy ? "Ver todas las publicaciones" : "Ver mis publicaciones"}</h3>
                        </div>
                    </div>


                    {searchOpen ? (
                        <div className={styles.searchContainer}>
                            <DropdownInput title="Sexo:" icon="fa-solid fa-venus-mars" value={sex} onChange={(e) => setSex(e.target.value)}
                                options={sexArray} label="sex" />

                            <DropdownInput title="Provincia:" icon="fa-solid fa-globe" value={provincia} onChange={async (e) => {
                                setLoading(true);
                                setProvincia(e.target.value);
                                setCanton('NA');
                                setDistrito('NA');
                                setCantonArray((await addressServiceInstance.cantonNA(e.target.value)).data);
                                setDistritoArray([]);
                                setLoading(false);
                            }} options={provinciaArray} label="provincia" />

                            {cantonArray.length > 1 ? (
                                <DropdownInput title="Cantón:" icon="fa-solid fa-globe" value={canton} onChange={async (e) => {
                                    setLoading(true);
                                    setCanton(e.target.value);
                                    setDistrito('NA');
                                    setDistritoArray((await addressServiceInstance.distritoNA(e.target.value)).data);
                                    setLoading(false);
                                }} options={cantonArray} label="canton" />
                            ) : (<></>)}

                            {distritoArray.length > 1 ? (
                                <DropdownInput title="Distrito:" icon="fa-solid fa-globe" value={distrito} onChange={(e) => {
                                    setDistrito(e.target.value);
                                }} options={distritoArray} label="distrito" />
                            ) : (<></>)}

                            <div ref={refSearchButton} className={styles.searchButton} onClick={async () => {
                                setLoading(true);
                                setPage(0);
                                var result = await animalAdoptionServiceInstance.getAll(sex, provincia, canton, distrito, "NA", 0);
                                if (result.code == 200) {
                                    setAnimals((result).data);
                                    if (result.data.length < 10) {
                                        setPage(-1);
                                    }
                                }
                                setLoading(false);
                                refSearchButton.current?.scrollIntoView({ behavior: 'smooth' });
                            }}>
                                <h3>Filtrar lista</h3>
                                <i className={`fa-solid fa-magnifying-glass`}></i>
                            </div>

                        </div>
                    ) : (<></>)}


                    {
                        animals.length > 0 ? (

                            <div className={styles.petsContainer}>
                                {
                                    animals.map((item) => (

                                        <div className={styles.petsCellContainer} key={item.id}>

                                            {item.is_shelter_house ? (
                                                <div className={styles.shelterContainer} onClick={()=>{
                                                    navigate('/ShelterDetailPage/' + item.idUser, { state: { item }, replace: false });
                                                }}>
                                                    <img src={myConst.baseUrlCdn + item.shelter_picture} />
                                                    <h2>{item.shelter_name}</h2>
                                                </div>) : (<></>)}

                                            <div className={styles.postTitleContainer}>
                                                <h5 className={styles.postTitle}>{myConst.UtilsInstance.camelize(item.title)}</h5>

                                                {myConst.UtilsInstance.getUser() && item.idUser == myConst.UtilsInstance.getUser().id ? (<i className="fa-solid fa-pencil" onClick={() => {
                                                    navigate('/PetAdoptionCreationPage', { state: { item }, replace: false });
                                                }}></i>) : (<></>)}

                                            </div>

                                            {item.images.length !== 0 ? (
                                                <div className={styles.gallery}>
                                                    <ImageGallery lazyload={true} showPlayButton={false} showThumbnails={false} items={myConst.UtilsInstance.gallery(item.images)} />
                                                </div>
                                            ) : (<></>)}

                                            <p className={styles.postDescription}>{item.description}</p>

                                            <div className={styles.petsCellTagsContainer}>
                                                <i className="fa-solid fa-venus-mars"></i>
                                                <p>{item.sex === "M" ? "Macho" : "Hembra"}</p>
                                            </div>

                                            <div className={styles.petsCellTagsContainer}>
                                                <i className="fa-solid fa-phone"></i>
                                                <p>{item.phone}</p>
                                            </div>

                                            <div className={styles.petsCellTagsContainer}>
                                                <i className="fa-solid fa-earth-americas"></i>
                                                <p>{item.nombre_provincia + " " + item.nombre_canton + " " + item.nombre_distrito}</p>
                                            </div>


                                            <div className={styles.cardIconContainers}>

                                                <div className={styles.iconText} onClick={() => {
                                                    navigate('/PetAdoptionDetailPage/' + item.id, { replace: false });
                                                }}>
                                                    <i className="fa-solid fa-comment"></i>
                                                    <p>{item.comments}</p>
                                                </div>

                                                <div className={styles.iconText} onClick={() => { refShareDialog.current.showDialog(myConst.appUrl + "PetAdoptionDetailPage/" + item.id); }}>
                                                    <p>Compartir</p>
                                                    <i className="fa-solid fa-share-nodes"></i>
                                                </div>
                                            </div>

                                            <div className={styles.line}>

                                            </div>

                                            <div className={styles.comment} onClick={() => {
                                                navigate('/PetAdoptionDetailPage/' + item.id, { replace: false });
                                            }}>
                                                <i className="fa-solid fa-comments"></i>
                                                <p>Comentar</p>
                                            </div>


                                        </div>

                                    ))
                                }

                            </div>

                        ) : (
                            <div className={styles.emptyContainer}>
                                <i className="fa-regular fa-rectangle-list"></i>
                                <h2>Aún no hay publicaciones</h2>
                            </div>
                        )
                    }

                    {
                        page != -1 ? (
                            <div className={styles.loadMoreContainer} onClick={async () => {
                                setLoading(true);
                                var result = await animalAdoptionServiceInstance.getAll(sex, provincia, canton, distrito, showOnlyMy ? myConst.UtilsInstance.getUser().id : 'NA', page + 1);
                                if (result.code == 200) {
                                    if (result.data.length < 10) {
                                        setPage(-1);
                                    } else {
                                        setPage(page + 1);
                                    }
                                    setAnimals([...animals, ...result.data]);
                                }
                                setLoading(false);
                            }}>
                                <h3>Cargar más elementos</h3>
                                <i className={`fa-solid fa-angle-down`}></i>
                            </div>
                        ) : (<></>)
                    }


                </div>


            </div>

            <img className="appIconBackground" src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />

            <ShareDialog ref={refShareDialog} />

            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, iconType: "info", function: async () => { } })
                }} />) : (<></>)
            }
        </div>
    );


}

export default PetAdoptionDashboardPage;