import styles from "./FindPage.module.scss";
import React, { useEffect, useState } from "react";
import { Route, useNavigate, useParams } from 'react-router-dom';

import animalServiceInstance from "api/animalService";

import Loading from "UI/components/Loading/Loading";
import InputIcon from "UI/components/InputIcon/InputIcon";
import DropdownInput from "UI/components/Dropdown/DropdownInput";
import AlertDialog, { ShowDialog } from "UI/components/AlertDialog/AlertDialog";
import Menu from "UI/components/Menu/Menu";

import * as myConst from 'common/common';
import * as myStrings from 'common/strings';

const PetCreationPage = () => {

    const [selectedImage, setSelectedImage] = useState("");
    const [name, setName] = useState("");
    const [specialNeeds, setSpecialNeeds] = useState("");
    const [castrated, setCastrated] = useState(1);
    const [sex, setSex] = useState('M');
    const [race, setRace] = useState("");


    const [nameUser, setNameUser] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");


    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: myStrings.generalAlertTitle, description: "", function: async () => { } });
    const navigate = useNavigate();
    const params = useParams();

    useEffect(async () => {window.scrollTo(0, 0);

        setLoading(true);
        var info = await animalServiceInstance.get(params.id);
        setLoading(false);

        if (info.code != 200) {
            ShowDialog(dialog, setDialog, myStrings.generalAlertTitle, "Error", async () => {
                navigate('/', { replace: false });
            }, info);
        } else {
            setName(info.data.name);
            setSpecialNeeds(info.data.special_needs);
            setCastrated(info.data.castrated);
            setSex(info.data.sex);
            setRace(info.data.race);
            setSelectedImage(info.data.image);

            setPhone(info.data.phone);
            setNameUser(info.data.userName);
            setAddress(info.data.address);
        }

    }, []);

    const sexArray = [{ name: 'Macho', code: 'M' }, { name: 'Hembra', code: 'H' }];
    const castratedArray = [{ name: 'Si', code: 1 }, { name: 'No', code: 0 }];


    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

                <Menu close={true} />

                <h2 className={`${styles.firstTitle} ${styles.loginTitle}`}>{"Datos de la mascota"}</h2>

                <div className={styles.mainContainerInside}>

                    {
                        selectedImage != "" && selectedImage != null ?
                            (<div className={styles.petImageContainer}>
                                <img id="dogImage" className={styles.petImage} src={myConst.baseUrlCdn + selectedImage} onClick={() => {
                                    document.getElementById('dogImage')?.requestFullscreen()
                                }} />
                            </div>) :
                            (<></>)
                    }

                    <InputIcon title="Nombre:" type="text" icon="fa-solid fa-signature"
                        value={name} onChange={(e) => { }} />

                    {
                        race != "" ?
                            (<InputIcon title="Raza:" type="text" icon="fa-solid fa-dog"
                                value={race} onChange={(e) => { }} />) : (<></>)
                    }

                    <InputIcon title="Castrado:" type="text" icon="fa-solid fa-scissors"
                        value={castrated === 1 ? "Si" : "No"} onChange={(e) => { }} />

                    <InputIcon title="Sexo:" type="text" icon="fa-solid fa-venus-mars"
                        value={sex === 'M' ? "Macho" : "Hembra"} onChange={(e) => { }} />

                    {
                        specialNeeds != "" ?
                            (<InputIcon title="Necesidades especiales:" type="text" icon="fa-solid fa-stethoscope" multiLine={true} max={500}
                                value={specialNeeds} onChange={(e) => { }} />) : (<></>)
                    }


                </div>

                <h2 className={styles.loginTitle}>{"Datos del dueño"}</h2>

                <div className={styles.mainContainerInside}>

                    <InputIcon title="Nombre:" type="text" icon="fa-solid fa-signature" value={nameUser} onChange={(inputValue) => { }} />

                    <InputIcon title="Teléfono:" type="text" icon="fa-solid fa-phone-volume" value={phone} onChange={(inputValue) => { }} />

                    <InputIcon title="Dirección:" type="text" icon="fa-solid fa-earth-americas" multiLine={true} max={500} value={address} onChange={(inputValue) => { }} />
                </div>
            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} onClick={async () => {
                    dialog.function();
                    setDialog({ ...dialog, show: false, title: myStrings.generalAlertTitle, function: async () => { } })
                }} />) : (<></>)
            }
        </div>
    );


}

export default PetCreationPage;