import styles from "./DashboardPage.module.scss";

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import animalServiceInstance from "api/animalService";

import Loading from "UI/components/Loading/Loading";
import Menu from "UI/components/Menu/Menu";

import AdoptionAnimalList from "UI/Views/petInsurance/adoptionAnimalList/AdoptionAnimalList";

import * as myConst from 'common/common';

const DashboardPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [animals, setAnimals] = useState([]);

    useEffect(async () => {window.scrollTo(0, 0);

        setLoading(true);
        if (await myConst.UtilsInstance.autoLogin()) { navigate('/LoginPage', { replace: true }); } else {
            var result = await animalServiceInstance.getAll(myConst.UtilsInstance.getUser().id);
            if (result.code == 200) {
                setAnimals(result.data);
            }
        }
        setLoading(false);

    }, []);


    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }


            <div className={styles.mainContainer}>

                <Menu />

                <div className={styles.mainContainerInside}>

                    <div onClick={() => {
                        navigate('/PetAdoptionDashboardPage', { replace: false });
                    }} className={styles.lookDogs}>
                        <h2>Animales en adopción</h2>
                        <i className={`fa-solid fa-chevron-right ${styles.movei}`}></i>
                    </div>

                    <AdoptionAnimalList/>


                    <h1 className={styles.sectionTitle}>Mis Mascotas</h1>
                    <p className={styles.sectionSubTitle}>(Registra a tus mascotas para asegurarlas mediante nuestro código QR)</p>

                    <div className={styles.myPetsHeader} onClick={() => {
                        navigate('/PetCreationPage', { replace: false });
                    }}>
                        <h3>Nueva Mascota</h3>
                        <i className="fa-solid fa-plus"></i>

                    </div>

                    {
                        animals.length > 0 ? (

                            <div className={styles.petsContainer}>
                                {
                                    animals.map((item) => (

                                        <div className={styles.petsCellContainer} key={item.id}>

                                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                                navigate('/PetCreationPage', { state: { item }, replace: false });
                                            }}>


                                                <div className={styles.petsCellTitleImage}>

                                                    {
                                                        item.image != "" && item.image != null ?
                                                            (
                                                                <div className={styles.petImageContainer}>
                                                                    <img id="dogImage" loading="lazy" className={styles.petImage} src={myConst.baseUrlCdn + item.image} />
                                                                </div>
                                                            ) : (<></>)

                                                    }


                                                    <h5>{myConst.UtilsInstance.camelize(item.name)}</h5>

                                                </div>

                                                {
                                                    item.race !== "" ? (<div className={styles.petsCellTagsContainer}>
                                                        <i className="fa-solid fa-dog"></i>
                                                        <p>{item.race}</p>
                                                    </div>) : (<></>)
                                                }

                                                <div className={styles.petsCellTagsContainer}>
                                                    <i className="fa-solid fa-scissors"></i>
                                                    <p>{item.castrated == true ? "Castrado" : "No castrado"}</p>

                                                </div>
                                                <div className={styles.petsCellTagsContainer}>
                                                    <i className="fa-solid fa-venus-mars"></i>
                                                    <p>{item.sex === "M" ? "Macho" : "Hembra"}</p>
                                                </div>

                                            </div>

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                                                <div className={styles.petsCellQrContainer} onClick={() => {
                                                    var data = item;
                                                    navigate('/CodeDetailPage', { state: { data }, replace: false });
                                                }}>
                                                    <p>Ver código</p>
                                                    <i className="fa-solid fa-qrcode"></i>
                                                </div>
                                            </div>



                                        </div>

                                    ))
                                }

                            </div>

                        ) : (<></>)
                    }


                </div>


            </div>

            <img className="appIconBackground" src={require('UI/assets/images/ZaguaTico_Blanco.svg').default} />
        </div>
    );


}

export default DashboardPage;