import styles from "./ErrorPage.module.scss";

import React from "react";
import {useNavigate } from 'react-router-dom';

import loginHeaderImage from "UI/assets/images/loginHeaderImage.svg";


const ErrorPage = () => {
   
    const navigate = useNavigate();

    return (

        <div>
           
            <div className={styles.mainContainer}>

                <div className={styles.mainContainerInside}>

                    <img src={loginHeaderImage} className={styles.loginHeaderImage} alt="Company Logo" />

                    <h2 className={styles.loginTitle}>Oopsss....</h2>
                    <p className={styles.loginTitle}>Esta página no existe</p>

                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {
                                navigate('/', { replace: true });

                            }}>Volver a inicio</button>
                    </div>

                </div>
            </div>


        </div>
    );


}

export default ErrorPage;