import styles from "./ShareDialog.module.scss";

import * as React from 'react';
import Dialog from '@mui/material/Dialog';

import {
    FacebookShareButton,
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

class ShareDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = { show: false, url: "" };
    }

    showDialog(url) {
        this.setState({ ...this.state, show: true, url: url })
    }

    render() {
        return <Dialog
            PaperProps={{ sx: { borderRadius: "25px", width: "300px" } }}
            BackdropProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none"
                }
            }}
            open={this.state.show}
        >

            <div className={styles.alertContainer}>

                <div className={styles.headerContainer}>
                    <div className={styles.headerContainerTitle}>
                        <i className="fa-solid fa-share-nodes"></i>
                        <p>Compartir</p>
                    </div>

                    <i className={`fa-solid fa-xmark ${styles.xmark}`} onClick={() => {
                        this.setState({ ...this.state, show: false })
                    }}></i>
                </div>


                <div className={styles.shareContainerr}>
                    <FacebookShareButton url={this.state.url}>
                        <FacebookIcon size={50} round />
                    </FacebookShareButton>

                    <TelegramShareButton url={this.state.url}>
                        <TelegramIcon size={50} round />
                    </TelegramShareButton>

                    <WhatsappShareButton url={this.state.url}>
                        <WhatsappIcon size={50} round />
                    </WhatsappShareButton>
                </div>
            </div>



        </Dialog>;
    }


}

export default ShareDialog;