import styles from "./AdoptionAnimalList.module.scss";

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import animalAdoptionServiceInstance from "api/animalAdoptionService";

import ShareDialog from "UI/components/shareDialog/ShareDialog";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import * as myConst from 'common/common';

const AdoptionAnimalList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [animals, setAnimals] = useState([]);

    useEffect(async () => {
        var result = await animalAdoptionServiceInstance.getAll("NA", "NA", "NA", "NA", "NA", 0);
        if (result.code == 200) {

            var list = [];
            result.data.forEach((item) => {
                if (item.images.length > 0) {
                    list.push(item);
                }
            });

            setAnimals(list);
        }
    }, []);

    const refShareDialog = useRef(null);

    const responsive = {
        all: {
            breakpoint: { max: 3000, min: 638 },
            items: 2.6,
        },
        mobile4: {
            breakpoint: { max: 638, min: 600 },
            items: 2.4,
        },
        mobile3: {
            breakpoint: { max: 600, min: 550 },
            items: 2.2,
        },
        mobile31: {
            breakpoint: { max: 550, min: 500 },
            items: 1.8,
        },
        mobile24: {
            breakpoint: { max: 500, min: 430 },
            items: 1.5,
        },
        mobile23: {
            breakpoint: { max: 430, min: 380 },
            items: 1.2,
        },
        mobile231: {
            breakpoint: { max: 380, min: 300 },
            items: 0.8,
        },
        mobile22: {
            breakpoint: { max: 300, min: 0 },
            items: 0.5,
        }
    };

    return (

        <div className={styles.mainContainer}>

            <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={true}
                centerMode={true}
               >
                {
                    animals.length > 0 ? (
                        animals.map((item) => (
                            <div className={styles.dogCardContainer} key={item.id}>
                                <img loading="lazy" src={myConst.baseUrlCdn + item.images[0].path} onClick={() => {
                                    navigate('/PetAdoptionDetailPage/' + item.id, { replace: false });
                                }}></img>
                                <div className={styles.iconText} onClick={() => { refShareDialog.current.showDialog(myConst.appUrl + "PetAdoptionDetailPage/" + item.id); }}>
                                    <i className="fa-solid fa-share-nodes"></i>
                                </div>
                            </div>
                        ))
                    ) : (<></>)
                }

            </Carousel>

            <ShareDialog ref={refShareDialog} />

        </div>
    );


}

export default AdoptionAnimalList;