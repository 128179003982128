import styles from "./InputImagePicker.module.scss";
import React, { useRef } from "react";

const InputImagePicker = (props) => {

    var elementRef = useRef(null);

    return (
        <div className={styles.inputImageOutside} onClick={() => {
            elementRef.click();
        }}>
            <div className={styles.inputInside}>
                <i className={`fa-solid fa-image  ${styles.iconColor}`}></i>

                <div className={styles.insideTextsContainer}>
                    {
                        props.other ? (<p className={styles.inputText}>{props.otherName? props.otherName : "Agregar otra"}</p>) : (<p className={styles.inputText}>Fotografía</p>)
                    }
                    {props.notOptional ? (<></>):(<p className={styles.inputTextHint}>(opcional)</p>)}
                </div>
            </div>
            <input
                ref={input => elementRef = input}
                className={styles.hideInput}
                type="file"
                name="myImage"
                title=" "
                accept="image/png, image/jpeg, image/jpg"
                onChange={props.onChange}
            />
        </div>
    );

}

export default InputImagePicker;