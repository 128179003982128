import * as React from 'react';

import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from "@mui/material/styles";


class SwitchComponent extends React.Component {

    constructor(props) {
        super(props);
       
        if(props.active){
            this.state = { enable: props.active };
        }else{
            this.state = { enable: false };
        }
    }

    enableSwitch() {
        this.setState({ ...this.state, enable: !this.state.enable })
    }

    render() {

        const theme = createTheme({
            components: {
                MuiSwitch: {
                    styleOverrides: {
                        colorPrimary: {
                            "&.Mui-checked": {
                                // Controls checked color for the thumb
                                color: "green"
                            }
                        },
                        track: {
                            // Controls default (unchecked) color for the track
                            opacity: 0.2,
                            backgroundColor: "green",
                            ".Mui-checked.Mui-checked + &": {
                                // Controls checked color for the track
                                opacity: 0.7,
                                backgroundColor: "green"
                            }
                        }
                    }
                }
            }
        });

        return (

            <ThemeProvider theme={theme}>
                <Switch
                    onClick={() => {
                        if (this.props?.action) {
                            this.props.action(this.props.value)
                        }
                    }}
                    checked={this.state.enable} />
            </ThemeProvider>

        );
    }
}

export default SwitchComponent;