import styles from "./CreateUserPage.module.scss";

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import userServiceInstance from "api/userService";

import loginHeaderImage from "UI/assets/images/welcomeLogo.png";

import Loading from "UI/components/Loading/Loading";
import InputIcon from "UI/components/InputIcon/InputIcon";
import AlertDialog from "UI/components/AlertDialog/AlertDialog";
import PowerBy from "UI/components/powerBy/PowerBy";

import * as myConst from 'common/common';

const CreateUserPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ show: false, title: "Atención", description: "", iconType: "info" });

    const navigate = useNavigate();

    useEffect(() => {window.scrollTo(0, 0);

    }, []);

    return (

        <div>
            {
                loading ? (<Loading />) : (<></>)
            }
            <div className={styles.mainContainer}>

                <div className={styles.mainContainerInside}>

                    <img src={loginHeaderImage} className={styles.loginHeaderImage} alt="Company Logo" />

                    <h2 className={styles.loginTitle}>¿Eres nuevo?</h2>

                    <InputIcon title="Correo electrónico:" type="email" icon="fa-solid fa-envelope" value={email} onChange={(inputValue) => {
                        setEmail(inputValue.target.value)
                    }} />

                    <InputIcon title="Contraseña:" initShowPassword={true} type="password" icon="fa-solid fa-lock" value={password} onChange={(inputValue) => {
                        setPassword(inputValue.target.value)
                    }} />

                    <InputIcon title="Nombre:" type="text" icon="fa-solid fa-signature" value={name} onChange={(inputValue) => {
                        setName(inputValue.target.value)
                    }} />

                    <p className={styles.hintData}>Los siguientes datos únicamente serán utilizados para ponerse en contacto contigo o entregarte tu mascota en caso de que esta se extravíe y sea encontrada</p>

                    <InputIcon title="Teléfono:" type="text" icon="fa-solid fa-phone-volume" value={phone} onChange={(inputValue) => {
                        setPhone(inputValue.target.value)
                    }} />

                    <InputIcon title="Dirección:" type="text" icon="fa-solid fa-earth-americas" multiLine={true} max={500} value={address} onChange={(inputValue) => {
                        setAddress(inputValue.target.value)
                    }} />

                    <div className={styles.loginBottonButton}>
                        <button
                            className={styles.primaryButton}
                            onClick={async () => {

                                if (
                                    name !== null && name !== "" &&
                                    email !== null && email !== "" &&
                                    password !== null && password !== "" &&
                                    phone !== null && phone !== "" &&
                                    address !== null && address !== ""
                                ) {

                                    if (
                                        myConst.UtilsInstance.checkEmail(email)) {
                                        setLoading(true);
                                        var result = await userServiceInstance.create(name, email, password, phone, address);
                                        setLoading(false);
                                        if (result.code != 200) {
                                            setDialog({ ...dialog, description: result.data, show: true, iconType: "error" })
                                        } else {
                                            myConst.UtilsInstance.setUser(result.data)
                                            navigate('/', { replace: true });
                                        }
                                    } else {
                                        setDialog({ ...dialog, description: "El formato de email no es válido", show: true , iconType: "error"})
                                    }

                                } else {
                                    setDialog({ ...dialog, description: "Toda la información es requerida", show: true , iconType: "error"})
                                }

                            }}>Crear cuenta</button>

                        <h3 className={styles.createAccountText} onClick={() => {
                            navigate('/LoginPage',   { state: { showLogin:true}, replace: false });
                        }}>¿Ya tienes cuenta?</h3>
                    </div>
                </div>

                <PowerBy />

            </div>
            {
                dialog.show ? (<AlertDialog title={dialog.title} description={dialog.description} iconType={dialog.iconType} onClick={async () => {
                    setDialog({ ...dialog, show: false, title: "Atención", iconType: "info" })
                }} />) : (<></>)
            }

        </div>
    );


}

export default CreateUserPage;