import * as myConst from '../common/common.js';

class ShelterService {

    update = async (id, name, description, image, phone, provincia, canton, distrito, provinciaName, cantonName, distritoName,lastImage) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({
                    id: id,
                    name: name,
                    description: description,
                    image: image,
                    phone: phone,
                    provincia: provincia,
                    canton: canton,
                    distrito: distrito,
                    provinciaName: provinciaName,
                    cantonName: cantonName,
                    distritoName: distritoName,
                    lastImage: lastImage,
                })
            };

            const response = await fetch(myConst.baseUrl + 'shelter/updateShelter', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

    inactive = async (id) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: myConst.headersBase,
                body: JSON.stringify({ id: id })
            };

            const response = await fetch(myConst.baseUrl + 'shelter/inactiveShelter', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return myConst.generalError;
        }
    };

}

const shelterServiceInstance = new ShelterService();
export default shelterServiceInstance;